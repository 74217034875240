import React, { useCallback, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Box, DialogActions, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FileRejection } from 'react-dropzone';
import { UploadFile, FileUploadEditableRow } from 'components/common';
import { ALL_ALLOWED_FILES, CHURN_ZERO_EVENT_NAMES } from 'constants/common';
import { fileUploadErrorHandler, nameLengthValidator } from 'helpers/common';
import {
  FormationsPrimaryButton,
  FormationsGhostButton,
} from 'components/common/Buttons';
import { Spacer } from 'components/Spacer';
import { styled } from '@mui/material/styles';
import { useCreateDocument, useCurrentAccount } from 'hooks/api';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import { queryClient } from 'states/reactQueryClient';
import { showErrorToast } from 'components/toast/showToast';
import { MAX_DOCUMENT_SIZE_BYTES } from 'constants/documents';
import { CreateDocumentForm } from 'services/documentTypes';
import { TempDocument } from '../types';
import { EditDocumentDialog } from './EditDocumentDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (documentIds: string[]) => void;
  additionalProps?: Partial<CreateDocumentForm>;
  title?: string;
  subtitle?: string;
  multiple?: boolean;
}

const Container = styled(Grid)(() => ({
  maxHeight: 256,
  overflowY: 'auto',
  flexWrap: 'nowrap',
}));

export const UploadDocumentDialog = ({
  isOpen,
  onClose,
  onSuccess = () => {},
  additionalProps,
  title,
  subtitle,
  multiple = true,
}: Props) => {
  const { t } = useTranslation();
  const { currentAccount } = useCurrentAccount();
  const accountId = currentAccount?.id ?? '';
  const companyId = currentAccount?.companyId ?? '';
  const [files, setFiles] = useState<TempDocument[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [editDocumentDialogVisible, setEditDocumentDialogVisible] =
    useState<boolean>(false);

  const handleCloseEditNoteDialog = () => {
    setEditDocumentDialogVisible(false);
  };

  const onUploadSingleFileSuccess = (documentId: string) => {
    setEditDocumentDialogVisible(false);
    onSuccess([documentId]);
  };

  const { mutateAsync: createDocument, isLoading: isDocumentCreating } =
    useCreateDocument({
      onSuccess: async () => {
        sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_UPLOAD);
        await queryClient.invalidateQueries([
          'documents',
          'accountId',
          accountId,
        ]);
      },
      onError: () => {
        showErrorToast(
          `Unable to upload document, request timed out. Please check your internet connection and try again.`,
        );
      },
    });

  const onDrop = useCallback(() => {
    setError(null);
  }, []);

  const hasFile = useMemo(() => files.length > 0, [files]);

  const hasMultipleFile = useMemo(() => files.length > 1, [files]);

  const onDropAccepted = (acceptedFiles: File[]) => {
    if (multiple) {
      setFiles([
        ...files,
        ...acceptedFiles.map((f) => {
          const dotIndex = f.name.lastIndexOf('.');
          const displayName = f.name.slice(0, dotIndex);
          const extension = f.name.slice(dotIndex);
          return {
            displayName,
            extension,
            file: f,
          };
        }),
      ]);
    } else {
      const f = acceptedFiles[0];
      const dotIndex = f.name.lastIndexOf('.');
      const displayName = f.name.slice(0, dotIndex);
      const extension = f.name.slice(dotIndex);
      setFiles([
        {
          displayName,
          extension,
          file: f,
        },
      ]);
    }
  };

  const handleDeleteFile = (file: TempDocument, index: number) => {
    setFiles(
      files.filter(
        (f, i) => !(f.displayName === file.displayName && i === index),
      ),
    );
  };

  const updateFileName = (file: TempDocument, index: number) => {
    setFiles([
      ...files.slice(0, index),
      file,
      ...files.slice(index + 1, files.length),
    ]);
  };

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileUploadErrorHandler(fileRejections, setError);
  }, []);

  const handleUploadFiles = async () => {
    // let documentCategoryId: string[] = [];
    try {
      const results = await Promise.all(
        files.map((file: TempDocument) =>
          createDocument({
            form: {
              accountId: additionalProps?.accountId ?? accountId,
              file: file.file,
              title: file.displayName + file.extension,
              year: additionalProps?.year ?? new Date().getFullYear(),
              companyId,
              documentCategoryId: additionalProps?.documentCategoryId,
            },
          }),
        ),
      );
      onSuccess(results.map((r) => r.data.id));
    } catch (e: unknown) {
      if (e instanceof Error) {
        showErrorToast(e.message);
      } else {
        showErrorToast('An error occurred while uploading files.');
      }
    }
  };

  const handleContinueUpload = () => {
    setEditDocumentDialogVisible(true);
  };

  const handleNextStep = async () => {
    if (hasMultipleFile) {
      await handleUploadFiles();
    } else {
      handleContinueUpload();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      data-testid="upload-document-dialog"
    >
      <DialogTitle sx={{ p: 3 }}>
        {title ?? t('documentHub.uploadDocument.title')}
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body2S"
          color="text.secondary"
          sx={{ mb: 2, pb: 2 }}
        >
          {subtitle ?? t('documentHub.uploadDocument.subtitle')}
        </Typography>
        <Spacer height={2} />
        <UploadFile
          uploadProps={{
            onDrop,
            onDropAccepted,
            onDropRejected,
            maxSize: MAX_DOCUMENT_SIZE_BYTES,
            accept: ALL_ALLOWED_FILES,
            multiple,
            noClick: true,
            noKeyboard: true,
            validator: nameLengthValidator,
          }}
        />
        <Typography variant="body3S" color="text.secondary" textAlign="center">
          {t('documentHub.uploadDocument.fileTypeSuggestion')}
        </Typography>
        <Spacer height={1} />
        {error && (
          <Box mt={1}>
            <Alert severity="warning">{error.message}</Alert>
          </Box>
        )}
        <Spacer height={3} />
        {hasMultipleFile ? (
          <Typography variant="body3B" color="text.primary">
            {t('documentHub.uploadDocument.listTitle', { count: files.length })}
          </Typography>
        ) : (
          <Spacer height={2} />
        )}
        <Spacer height={1} />
        <Container
          container
          flexDirection="column"
          data-testid="documents-list"
        >
          {hasFile &&
            files.map((file, index) => (
              <FileUploadEditableRow
                // eslint-disable-next-line react/no-array-index-key
                key={`${file.displayName}-${index}`}
                index={index}
                file={file}
                handleDeleteFile={handleDeleteFile}
                updateFileName={updateFileName}
              />
            ))}
        </Container>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <FormationsGhostButton
          size="large"
          onClick={onClose}
          data-testid="form-cancel-btn"
          disabled={false}
        >
          {t('actions.cancel')}
        </FormationsGhostButton>
        <FormationsPrimaryButton
          isLoading={isDocumentCreating}
          size="large"
          type="submit"
          data-testid="form-save-btn"
          disabled={files.length === 0}
          onClick={handleNextStep}
        >
          {hasFile && !hasMultipleFile
            ? t('actions.continue')
            : t('actions.upload')}
        </FormationsPrimaryButton>
      </DialogActions>
      {editDocumentDialogVisible && (
        <EditDocumentDialog
          file={files[0]}
          isOpen
          onClose={handleCloseEditNoteDialog}
          onSuccess={onUploadSingleFileSuccess}
          additionalProps={additionalProps}
        />
      )}
    </Dialog>
  );
};
