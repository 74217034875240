import React, { useCallback, useState } from 'react';
import { useCurrentAccount } from 'hooks/api/useAccounts';
import { useNavigate } from 'hooks/api';
import {
  AttachMoneyIcon,
  ChatIcon,
  AddCircleOutlineIcon,
  FolderOpenIcon,
  ListAltIcon,
} from 'components/common/Icon';
import { UploadDocumentDialog } from 'components/documentsV2/dialogs/UploadDocumentDialog';
import { UploadSuccessDialog } from 'components/documentsV2/dialogs/UploadSuccessDialog';
import { TaxesDirectPaymentFormDialog } from 'components/taxes/dialogs/TaxesDirectPaymentFormDialog';
import { directPaymentSchema } from 'schemas/directPaymentSchema';
import { useTaxYearsOptions } from 'components/taxes/TaxesUI';
import { Routes } from 'fnRoutes';
import { useTranslation } from 'react-i18next';
import {
  disabledForStatuses,
  MenuListItem,
} from 'components/common/TopNavBar/types';
import {
  isAccount1120sOnly,
  isAccountBusinessInABox,
} from 'components/ProgressTracker/util';
import { accountIsAny } from 'components/common/sidebar/constant';
import { NavMenuItem } from './NavMenuItem';

export const QuickActionMenu = () => {
  const { t } = useTranslation();
  const { currentAccount: account } = useCurrentAccount();
  const navigate = useNavigate();
  const customerStatus = account?.status?.label ?? '';
  const [visibleDialog, setVisibleDialog] = useState<
    'uploadFile' | 'uploadSuccess' | null
  >(null);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const fieldsWithYearOptions = useTaxYearsOptions();
  const accountId = account?.id;

  const navigateToAddTransactions = useCallback(() => {
    navigate(Routes.ACCOUNTING_ADD_TRANSACTION);
  }, []);

  const menuList: MenuListItem[] = [
    {
      name: t('topNavBar.quickActions.addDocument'),
      clickHandler: () => setVisibleDialog('uploadFile'),
      icon: FolderOpenIcon,
    },
    {
      name: t('topNavBar.quickActions.addTransaction'),
      icon: ListAltIcon,
      clickHandler: navigateToAddTransactions,
      disabledForStatus: ['NEW'],
      shouldDisable: accountIsAny([
        disabledForStatuses(['NEW']),
        isAccount1120sOnly,
        isAccountBusinessInABox,
      ]),
    },
    {
      name: t('topNavBar.quickActions.addTaxPayment'),
      icon: AttachMoneyIcon,
      clickHandler: () => setShowFormDialog(true),
      shouldDisable: accountIsAny([
        disabledForStatuses(['NEW']),
        isAccount1120sOnly,
        isAccountBusinessInABox,
      ]),
    },
    {
      name: t('topNavBar.quickActions.reportIssue'),
      icon: ChatIcon,
      disabledForStatus: ['NEW'],
      shouldDisable: accountIsAny([disabledForStatuses(['NEW'])]),
      navigation: {
        href: 'https://learn.formationscorp.com/kb-tickets/new',
      },
    },
  ];

  if (account == null) {
    return null;
  }

  return (
    <>
      <NavMenuItem
        tooltipTitle={t('topNavBar.quickActions.tooltipTitle')}
        menuList={menuList}
        mainIcon={AddCircleOutlineIcon}
        menuName={t('topNavBar.quickActions.name')}
        customerStatus={customerStatus}
        account={account}
      />

      {/* Upload Document Dialog */}
      {visibleDialog === 'uploadFile' && (
        <UploadDocumentDialog
          isOpen
          onSuccess={() => setVisibleDialog('uploadSuccess')}
          onClose={() => setVisibleDialog(null)}
        />
      )}
      {visibleDialog === 'uploadSuccess' && (
        <UploadSuccessDialog isOpen onClose={() => setVisibleDialog(null)} />
      )}

      {/* Upload Direct Payment Dialog */}
      <TaxesDirectPaymentFormDialog
        isOpen={showFormDialog}
        onClose={() => setShowFormDialog(false)}
        fields={fieldsWithYearOptions}
        data-testid="upload-tax-payment-dialog"
        validationSchema={directPaymentSchema}
        accountId={accountId}
      />
    </>
  );
};
