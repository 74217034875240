import { get, isObject, isString } from 'lodash';
import { AxiosError } from 'axios';

const DEFAULT_MSG = 'An error has occurred, please try again.';
const extractMessage = (resp: unknown, defaultMsg: string = DEFAULT_MSG) => {
  if (isString(resp)) {
    return resp
  }
  if (isObject(resp) && get(resp, 'message') != null) {
    return get(resp, 'message', defaultMsg)!;
  }
  return defaultMsg;
};

export const getErrorMessage = (
  error: unknown,
  defaultMsg: string = DEFAULT_MSG,
): string => {
  if ((error as AxiosError)?.isAxiosError === true) {
    return extractMessage(get(error, 'response.data', defaultMsg));
  }
  if (error instanceof Error) {
    return error.message;
  }
  if (isString(error)) {
    return error;
  }
  return defaultMsg;
};

/**
 * The UploadError class extends the built-in JavaScript Error class and is used specifically
 * for handling errors that occur during the file upload process.
 *
 * It provides additional context for these errors by including the file that caused the error
 * as a property of the error instance.
 *
 * Properties:
 * - message: A string that describes the error.
 * - file: An optional File object that represents the file that caused the error.
 * - name: A string that identifies the type of the error. It is set to 'UploadError'.
 *
 * The UploadError class also uses the Error.captureStackTrace method, if available, to create
 * a stack trace for the error. This stack trace excludes the UploadError constructor from the
 * trace, which can make it easier to identify where the error actually occurred in the code.
 */
export class UploadError extends Error {
  file?: File;

  constructor(message: string, file?: File) {
    super(message);

    this.name = 'UploadError';

    this.file = file;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}