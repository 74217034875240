import { ApiResponse } from 'models/api';
import { Domain } from 'models/domains';
import { api } from './axios';

export type StripeCustomer = {
  id: string;
  accountId: string | null;
  stripeId: string;
  name: string;
  email: string;
  plan: string;
  planAmount: number;
  priceActive: boolean;
  planStatus: string;
  billingFrequency: 'day' | 'week' | 'month' | 'year';
  billingIntervalCount: number;
  nextInvoiceDate: string;
  nextInvoiceAmount: number | null;
  billingCycleAnchor: string;
  lastPaymentDate: string;
  lastPaymentAmount: number;
  lastPaymentStatus: 'paid' | 'unpaid' | 'void' | 'pending';
  couponAmountOff: number | null;
  couponName: string | null;
  couponDuration: 'forever' | 'once' | 'repeating' | null;
  couponDurationInMonths: number | null;
  dataSyncedAt: string;
  isPrimary?: boolean;
};



export type StripeSessionStatus = {
  payment_status: string;
  subscription: {
    id: string
  }
};

export const checkStripePlan = async (
  accountId?: string,
): Promise<StripeCustomer | null> => {
  const { data } = await api.get<StripeCustomer | null>(
    `stripe/plan/check/${accountId}`,
    {
      timeout: 30000, // the request from stripe is slow
    },
  );
  return data;
};

export const checkStripePaymentStatus = async (
  accountId: string,
  sessionId: string,
): Promise<StripeSessionStatus> => {
  const { data } = await api.get<StripeSessionStatus>(
    `stripe/session/${accountId}/${sessionId}`,
  );
  return data;
};

export const listAllPlans = async (): Promise<string[]> => {
  const { data } = await api.get<string[]>('stripe/plans');
  return data;
};

export const getStripeCustomer = async (
  accountId?: string,
): Promise<StripeCustomer[]> => {
  const { data } = await api.get<StripeCustomer[]>(
    `stripe/customers/${accountId}`,
  );
  return data;
};

export const linkStripeCustomer = async (params: {
  stripeId: string;
  accountId: string;
  isPrimary?: boolean;
}) => {
  await api.post('stripe/customers/link', params);
};

export const unlinkStripeCustomer = async (data: {
  stripeId: string;
  accountId: string;
}) => {
  await api.delete('stripe/customers/link', {
    data,
  });
};

export const createSQSCheckoutSession = async (
  accountId: string,
  domains: Domain[],
): Promise<ApiResponse<{ client_secret: string }>> =>
  api.post(`stripe/squarespace/${accountId}/checkout-session`, {
    domains: domains.map((domain) => ({
      name: domain.domain,
      price: domain.price,
    })),
  });
