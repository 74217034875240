import {
  ProgressTrackerGroup,
  ProgressTrackerStages,
  ProgressTrackerStatus,
} from 'models/account';
import { isStageComplete, isStageStatus } from 'helpers/progressTracker';
import { useEffect, useMemo } from 'react';
import { queryClient } from 'states/reactQueryClient';
import { Company } from 'models/company';
import { useInitIncorporationStatus } from 'hooks/api';
import { ENTITY_MAPPING } from 'constants/common';

const finishAllCustomerTasks = (progress: ProgressTrackerGroup[]) => {
  const completeCompanyDetails = isStageComplete(
    ProgressTrackerStages.CompanyDetails,
    progress,
  );
  const completePersonalDetails = isStageComplete(
    ProgressTrackerStages.PersonalDetails,
    progress,
  );
  const completeAddressDetails = isStageComplete(
    ProgressTrackerStages.AddressDetails,
    progress,
  );
  const uploadIdentityProof =
    isStageStatus(
      ProgressTrackerStages.UploadIdentityProof,
      progress,
      ProgressTrackerStatus.Completed,
    ) ||
    isStageStatus(
      ProgressTrackerStages.UploadIdentityProof,
      progress,
      ProgressTrackerStatus.InProgress,
    );
  const uploadLastYearTaxStatement =
    isStageStatus(
      ProgressTrackerStages.UploadLastYearTaxStatement,
      progress,
      ProgressTrackerStatus.Completed,
    ) ||
    isStageStatus(
      ProgressTrackerStages.UploadLastYearTaxStatement,
      progress,
      ProgressTrackerStatus.InProgress,
    ) ||
    isStageStatus(
      ProgressTrackerStages.UploadLastYearTaxStatement,
      progress,
      ProgressTrackerStatus.Rejected,
    );
  const completeHomeAddressDetails = isStageComplete(
    ProgressTrackerStages.HomeAddressDetails,
    progress,
  );
  return (
    completeCompanyDetails &&
    completePersonalDetails &&
    completeAddressDetails &&
    uploadIdentityProof &&
    uploadLastYearTaxStatement &&
    completeHomeAddressDetails
  );
};

export const useAutoInitIncorporationStatus = (
  company: Company | undefined,
  accountProgress: ProgressTrackerGroup[],
) => {
  const { initIncorporationStatusAsync } = useInitIncorporationStatus(
    company?.id ?? '',
  );
  const finishedAll = useMemo(
    () => finishAllCustomerTasks(accountProgress),
    [accountProgress],
  );
  useEffect(() => {
    (async function () {
      if (
        finishedAll &&
        company?.id != null &&
        company?.id !== '' &&
        company?.bibHubspot == null &&
        company?.entityType === ENTITY_MAPPING.sole_prop
      ) {
        await initIncorporationStatusAsync();
        await queryClient.invalidateQueries(['getCompany', company?.id]);
      }
    })();
  }, [
    finishedAll,
    company?.bibHubspot,
    company?.id,
    initIncorporationStatusAsync,
  ]);
};
