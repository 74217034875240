import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'helpers/text-transformer';
import { FormationsPrimaryButton } from 'components/common/Buttons';
import { Company } from 'services';

export const SelectedBankInfo = ({
  company,
  onContinueToNextSection,
}: {
  company: Partial<Company>;
  onContinueToNextSection: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5B" component="h5" sx={{ mb: 2 }}>
        {t('Bank Information')}
      </Typography>
      <Typography variant="body2" component="p">
        {t('Routing Number')}
      </Typography>
      <Typography variant="body2B" component="p" sx={{ mb: 1 }}>
        {company?.routingNumber}
      </Typography>

      <Typography variant="body2" component="p">
        {t('Account Number')}
      </Typography>
      <Typography variant="body2B" component="p" sx={{ mb: 1 }}>
        {company?.bankAccountNumber}
      </Typography>

      <Typography variant="body2" component="p">
        {t('Account Type')}
      </Typography>
      <Typography variant="body2B" component="p">
        {company?.bankAccountType &&
          capitalizeFirstLetter(company.bankAccountType)}
      </Typography>
      <Box mt={4}>
        <FormationsPrimaryButton onClick={onContinueToNextSection}>
          Continue to Business Packet
        </FormationsPrimaryButton>
      </Box>
    </>
  );
};
