import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseQueryResult,
  UseMutationOptions,
} from 'react-query';
import { AccountService, getAccountsXero } from 'services';
import {
  AccountStatus,
  defaultPageInfo,
  getAccount,
  getAccountsList,
  getAccountsMeta,
  GetAccountsListProps,
  IAccount,
  IMetadata,
  updateStatus,
  getXeroConnectionHistory,
  getCSMList,
  getBookkeepersList,
  BookkeeperOption,
  activateAccount,
  updateAccountPreference,
  getAccountPreference,
  UpdateAccountPreferencePayload,
} from 'services/account';
import { sendProgressTrackerEvent } from 'helpers/heap/progressTrackerEvent';
import {
  IAccountListItem,
  IAccountRequest,
  ProgressTrackerEvent,
  IAccountXeroInfo,
  IAccountXeroConnectionHistory,
  AccountPreference,
} from 'models/account';
import { queryClient } from 'states/reactQueryClient';
import { disconnectXero } from 'services/xero';
import { SortingDirection, SortOrderEnumParam } from 'enums';
import {
  BooleanParam,
  NumberParam,
  QueryParamConfigMap,
  SetQuery,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import moment from 'moment';
import { defaultTablePageSize } from 'constants/common';
import { useCurrentUser, useUser } from './useUser';
import { ApiListResp, PageInfo } from '../../models/api';

export type AccountFilters = Partial<{
  accountId: string;
  orderBy: string | null;
  search: string | null;
  keyword: string | null;
  status: string | null;
  entityType: string | null;
  from: string | null;
  to: string | null;
  taxLiabilityStatus?: string | null;
  taxLiabilityYear?: string | null;
  documentNotes: string | null;
  order: SortingDirection;
  role: string | null;
  assignedCSM: string | null;
  state: string | null;
  lastLoginDate: string | null;
  stateOfIncorporation: string | null;
  plan: string | null;
  xeroBroken?: boolean;
  oilPending?: boolean;
  bookkeeper?: string | null;
  formationsPlan?: string | null;
  dataSource?: string | null;
  bankConnectionStatus?: string | null;
  payrollOnboarding?: string | null;
}> & {
  page: number;
  size: number;
};

export const accountQueryParams = {
  page: withDefault(NumberParam, 1),
  size: withDefault(NumberParam, defaultTablePageSize),
  order: withDefault(SortOrderEnumParam, SortingDirection.Desc),
  orderBy: withDefault(StringParam, 'registeredAt'),
  search: withDefault(StringParam, ''),
  keyword: withDefault(StringParam, ''),
  status: withDefault(StringParam, ''),
  entityType: withDefault(StringParam, ''),
  from: withDefault(StringParam, null),
  to: withDefault(StringParam, null),
  taxLiabilityStatus: withDefault(StringParam, ''),
  taxLiabilityYear: withDefault(StringParam, ''),
  documentNotes: withDefault(StringParam, ''),
  role: withDefault(StringParam, 'Customer'),
  assignedCSM: withDefault(StringParam, ''),
  state: withDefault(StringParam, ''),
  lastLoginDate: withDefault(StringParam, ''),
  stateOfIncorporation: withDefault(StringParam, ''),
  plan: withDefault(StringParam, ''),
  xeroBroken: withDefault(BooleanParam, undefined),
  oilPending: withDefault(BooleanParam, undefined),
  bookkeeper: withDefault(StringParam, null),
  formationsPlan: withDefault(StringParam, null),
  dataSource: withDefault(StringParam, null),
  bankConnectionStatus: withDefault(StringParam, null),
  payrollOnboarding: withDefault(StringParam, null),
};

export const useAccountsQuery = (): Readonly<
  [query: AccountFilters, setQuery: SetQuery<QueryParamConfigMap>]
> => {
  const [query, setQuery] = useQueryParams(accountQueryParams);
  return [query, setQuery];
};

// convert date filter from YYYY-MM-DD format to ISO string.
const transformDateFromParam = (
  date: string | null | undefined,
): string | undefined => {
  if (date) {
    return moment(date).toISOString();
  }
  return date ?? undefined;
};
const transformDateToParam = (
  date: string | null | undefined,
): string | undefined => {
  if (date) {
    return moment(date).add(1, 'day').toISOString();
  }
  return date ?? undefined;
};
const transformLastLoginDate = (date: string | null | undefined): string =>
  date ? moment(date).toISOString() : '';
const transformField = (value: string | null | undefined): string =>
  value ?? '';
const transformPayrollOnboarding = (
  value: string | null | undefined,
): string | undefined => {
  if (
    value === null ||
    value === undefined ||
    !['true', 'false'].includes(value)
  ) {
    return undefined;
  }
  return value;
};
export const transformAccountQueryParams = (
  params: AccountFilters,
): GetAccountsListProps => ({
  page: params.page,
  size: params.size,
  search: params.search ?? undefined,
  from: transformDateFromParam(params.from),
  to: transformDateToParam(params.to),
  status: params.status as AccountStatus,
  sortingName: transformField(params.orderBy),
  sortingDirection: params.order as SortingDirection,
  entityType: transformField(params.entityType),
  taxLiabilityStatus: transformField(params.taxLiabilityStatus),
  taxLiabilityYear: transformField(params.taxLiabilityYear),
  documentNotes: transformField(params.documentNotes),
  role: transformField(params.role),
  csm: transformField(params.assignedCSM),
  state: transformField(params.state),
  lastLoginDate: transformLastLoginDate(params.lastLoginDate),
  stateOfIncorporation: transformField(params.stateOfIncorporation),
  plan: transformField(params.plan),
  xeroBroken: params.xeroBroken ?? undefined,
  oilPending: params.oilPending ?? undefined,
  bookkeeper: params.bookkeeper ?? undefined,
  formationsPlan: params.formationsPlan ?? undefined,
  dataSource: params.dataSource ?? undefined,
  bankConnectionStatus: params.bankConnectionStatus ?? undefined,
  payrollOnboarding:
    transformPayrollOnboarding(params.payrollOnboarding) ?? undefined,
});

type IUseAccountsList = ApiListResp<IAccountListItem[]>;
export const useAccountsList = (
  props: GetAccountsListProps,
): Omit<UseQueryResult<IUseAccountsList>, 'data'> & {
  accounts: IAccountListItem[];
  pageInfo: PageInfo;
} => {
  const { data, ...rest } = useQuery<IUseAccountsList>(
    ['accounts', props],
    () => getAccountsList(props),
    // NOTE: Disabled query caching for now because cache needs to be invalidated when
    // taxFormCompletionStatus updates, but the place where this updates do not have all
    // the rest of the query key components to invalidate the cache.
    { cacheTime: 0 },
  );

  return {
    accounts: data?.data || [],
    pageInfo: data?.pageInfo || defaultPageInfo,
    ...rest,
  };
};

export const useAccount = (
  id: string | undefined,
  queryProps?: UseQueryOptions<IAccount>,
) => {
  const { data, ...rest } = useQuery<IAccount>(
    ['account', id],
    () => getAccount(id!),
    {
      enabled: !!id,
      ...queryProps,
    },
  );

  return {
    account: data,
    ...rest,
  };
};

export const useAccountByUserId = (userId: string) => {
  const { user } = useUser(userId);
  return useAccount(user?.accountId);
};

export const useCurrentAccount = (queryProps?: UseQueryOptions<IAccount>) => {
  const { currentUser } = useCurrentUser();
  const accountId = currentUser?.accountId || '';

  const { data, ...rest } = useQuery<IAccount>(
    ['progressTracker', accountId],
    () => getAccount(accountId),
    {
      enabled: !!accountId,
      ...queryProps,
    },
  );
  return {
    currentAccount: data,
    ...rest,
  };
};

export const useCurrentFormationsPlan = () => {
  const { currentAccount } = useCurrentAccount();
  return currentAccount?.formationsPlan;
};

type UpdateAccountVariables = Omit<Partial<IAccountRequest>, 'id'>;
export const useUpdateAccount = (
  accountId?: string,
  updateProps?: UseMutationOptions<unknown, unknown, UpdateAccountVariables>,
) =>
  useMutation<unknown, unknown, UpdateAccountVariables>(
    (params) => {
      if (accountId) {
        return AccountService.updateAccountById(accountId, params);
      }
      throw new Error('Account is not found');
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['account', accountId]);
        await queryClient.invalidateQueries(['progressTracker', accountId]);
        await queryClient.invalidateQueries(['edit-history']);
      },
      ...updateProps,
    },
  );

export const useUpdateCurrentAccount = (
  updateProps?: UseMutationOptions<unknown, unknown, UpdateAccountVariables>,
) => {
  const { currentUser } = useCurrentUser();
  const accountId = currentUser?.accountId || undefined;
  return useUpdateAccount(accountId, updateProps);
};

const defaultAccountMeta = {
  total: 0,
  types: [],
};
export const useAccountsMeta = () => {
  const { data, ...rest } = useQuery<IMetadata>(['accountsMeta'], () =>
    getAccountsMeta(),
  );
  return {
    accountMeta: data || defaultAccountMeta,
    ...rest,
  };
};

interface UpdateStatusVariables {
  id: string;
  label: AccountStatus;
}
export const useUpdateAccountStatus = (
  mutateProp?: UseMutationOptions<unknown, unknown, UpdateStatusVariables>,
) =>
  useMutation(
    ({ id, label }: UpdateStatusVariables) => updateStatus(id, label),
    mutateProp,
  );

type UpdateProgressProps = Pick<IAccountRequest, 'progress'> & {
  eventData?: ProgressTrackerEvent;
};
export const useUpdateAccountProgress = (
  accountId: string | undefined,
  mutationOptions?: UseMutationOptions<
    unknown,
    unknown,
    UpdateAccountVariables
  >,
) => {
  const { mutateAsync, ...rest } = useUpdateAccount(accountId, mutationOptions);

  return {
    mutateAsync: async (updateProps: UpdateProgressProps) => {
      const { progress, eventData } = updateProps;
      try {
        await mutateAsync({ progress });
        if (eventData) {
          sendProgressTrackerEvent({
            accountId,
            ...eventData,
          });
        }
      } catch (e) {
        if (eventData) {
          sendProgressTrackerEvent({
            accountId,
            ...eventData,
            status: 'error',
          });
        }
      }
    },
    ...rest,
  };
};

export const useXeroInfo = (accountId: string | undefined) => {
  const { data, ...rest } = useQuery<IAccountXeroInfo, Error>(
    ['xerostatus', accountId],
    () => getAccountsXero(accountId!),
    { enabled: !!accountId },
  );

  return {
    xeroInfo: data,
    ...rest,
  };
};

export const useDisconnectXero = (accountId: string) => {
  const { mutateAsync, mutate, ...rest } = useMutation(
    (force: boolean) => disconnectXero(accountId, force),
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries(['xerostatus', accountId]),
          queryClient.invalidateQueries(['account', accountId]),
        ]),
    },
  );
  return {
    disconnectXeroAsync: mutateAsync,
    disconnectXero: mutate,
    ...rest,
  };
};

export const useXeroConnectionHistory = (accountId: string | undefined) => {
  const { data, ...rest } = useQuery<IAccountXeroConnectionHistory[], Error>(
    ['xeroConnectionHistory', accountId],
    () => getXeroConnectionHistory(accountId!),
    { enabled: !!accountId },
  );

  return {
    connectionHistory: data,
    ...rest,
  };
};

export const useCSMList = () => {
  const { data: csms, ...rest } = useQuery<string[], unknown>(['csms'], () =>
    getCSMList(),
  );

  return {
    csms,
    ...rest,
  };
};

export const useBookkeepersList = () => {
  const { data: bookkeepers, ...rest } = useQuery<BookkeeperOption[], unknown>(
    ['bookkeepers'],
    getBookkeepersList,
  );

  return {
    bookkeepers: bookkeepers ?? [],
    ...rest,
  };
};

export const useBookkeeperOptions = () => {
  const { bookkeepers, ...rest } = useBookkeepersList();
  return {
    bookkeeperOptions: [
      { id: 'unassigned', name: 'Unassigned' },
      ...bookkeepers,
    ].map((bk) => ({
      value: bk.id,
      label: bk.name,
    })),
    ...rest,
  };
};

export const useActivateAccount = (accountId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(() =>
    activateAccount(accountId),
  );

  return {
    activateAccount: mutate,
    activateAccountAsync: mutateAsync,
    ...rest,
  };
};

export const useAccountPreference = (accountId: string) => {
  const { data, ...rest } = useQuery<AccountPreference>(
    ['accountPreference', accountId],
    () => getAccountPreference(accountId),
  );

  return {
    accountPreference: data,
    ...rest,
  };
};

export const useUpdateAccountPreference = (accountId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (payload: UpdateAccountPreferencePayload) =>
      updateAccountPreference(accountId, payload),
  );
  return {
    updateAccountPreference: mutate,
    updateAccountPreferenceAsync: mutateAsync,
    ...rest,
  };
};
