import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import {
  createDocumentByAccountId,
  deleteDocument,
  getDocumentCategories,
  getDocuments,
  getDocumentEmails,
  updateDocument,
  getDocumentById,
  approveDocument,
  rejectDocument,
  defaultApiResponseMetaData,
  GetDocumentsParams,
  downloadDocument,
  listDocumentNote,
  createDocumentNote,
  markDocumentNoteRead,
  CreateDocumentData,
  increaseCustomerReadCount,
} from 'services/document';
import { FormationsDocument } from 'hooks/dataFormatters/useDocumentsTableData';
import {
  CreateDocumentForm,
  DocumentCategory,
  DocumentEmail,
  IDocumentNote,
  UpdateDocumentForm,
} from 'services/documentTypes';
import { getDocumentAvailableForCategory } from 'helpers/documents';
import { IDocumentCategory } from 'components/FilePreview/FilePreviewComponent';
import {
  ArrayParam,
  NumberParam,
  QueryParamConfigMap,
  SetQuery,
  StringParam,
  useQueryParams,
  withDefault,
  BooleanParam,
} from 'use-query-params';
import { ApiListResp, ApiResponse, PageInfo } from 'models/api';
import { SortingDirection, SortOrderEnumParam } from 'enums';
import { DirectPaymentFormData } from 'components/taxes/types';
import moment from 'moment';
import { defaultTablePageSize } from 'constants/common';

export type DocumentFilters = Partial<{
  accountId: string;
  order: SortingDirection;
  orderBy: string | null;
  keyword: string | null;
  from: string | null;
  to: string | null;
  department: (string | null)[] | null;
  forYear: (string | null)[] | null;
  category: (string | null)[] | null;
  subcategory: (string | null)[] | null;
  documentHasNotes: boolean | null;
}> & {
  page: number;
  size: number;
};

export const emptyDocumentFilter: DocumentFilters = {
  size: 10,
  page: 1,
  keyword: undefined,
};

export const documentQueryParams = {
  page: withDefault(NumberParam, 1),
  size: withDefault(NumberParam, defaultTablePageSize),
  order: withDefault(SortOrderEnumParam, undefined),
  orderBy: StringParam,
  keyword: StringParam,
  from: StringParam,
  to: StringParam,
  department: ArrayParam,
  forYear: ArrayParam,
  category: ArrayParam,
  subcategory: ArrayParam,
  documentHasNotes: BooleanParam,
};

export const getDocumentsByCompany = async (
  companyId: string,
  page: string,
  size?: string,
  keyword?: string,
): Promise<ApiListResp<FormationsDocument[]>> => {
  if (!companyId) return { data: [], pageInfo: defaultApiResponseMetaData };
  return getDocuments({
    companyId,
    page,
    size,
    keyword,
  });
};

export const getDocumentsByCategoryAndSubcategory = async (
  companyId: string,
  page: string,
  category: string[],
  subcategory: string[],
  keyword?: string,
): Promise<ApiListResp<FormationsDocument[]>> => {
  if (!companyId) return { data: [], pageInfo: defaultApiResponseMetaData };
  return getDocuments({
    companyId,
    page,
    category,
    subcategory,
    keyword,
  });
};

export const getDocumentsByAccountId = async (
  accountId: string,
  params: Partial<DocumentFilters>,
): Promise<ApiListResp<FormationsDocument[]>> => {
  if (!accountId) return { data: [], pageInfo: defaultApiResponseMetaData };
  return getDocuments({ accountId, ...params });
};

// Hooks
type QueryOptions<T> = Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>;
type QueryResult<T> = Omit<UseQueryResult<T>, 'data'>;
type DocumentsQueryResult = QueryResult<ApiListResp<FormationsDocument[]>> & {
  documents: FormationsDocument[];
  pageInfo: PageInfo;
};
type CategoriesQueryResult = QueryResult<DocumentCategory[]> & {
  categories: DocumentCategory[];
};
type EmailsQueryResult = QueryResult<DocumentEmail[]> & {
  emails: DocumentEmail[];
};
type MutateOptions<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
> = Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>;

export const documentsQueryParams = {
  page: withDefault(NumberParam, 1),
  size: withDefault(NumberParam, defaultTablePageSize),
};

export const useDocumentsQuery = (
  params: GetDocumentsParams,
  queryOps?: QueryOptions<ApiListResp<FormationsDocument[]>>,
): DocumentsQueryResult => {
  const { data, ...rest } = useQuery<
    ApiListResp<FormationsDocument[]>,
    unknown
  >(['documents', params], () => getDocuments(params), queryOps);

  return {
    documents: data?.data || ([] as FormationsDocument[]),
    pageInfo: data?.pageInfo || defaultApiResponseMetaData,
    ...rest,
  };
};

interface DocumentsByCompanyProps {
  id: string;
  page: string;
  size?: string;
}
export const useDocumentsByCompany = (
  { id, page, size }: DocumentsByCompanyProps,
  queryProps?: QueryOptions<ApiListResp<FormationsDocument[]>>,
): DocumentsQueryResult => {
  const { data, ...rest } = useQuery<
    ApiListResp<FormationsDocument[]>,
    unknown
  >(
    ['documents', 'companyId', id, page, size],
    () => getDocumentsByCompany(id, page, size),
    queryProps,
  );

  return {
    documents: data?.data || [],
    pageInfo: data?.pageInfo || defaultApiResponseMetaData,
    ...rest,
  };
};

export const useDocumentsByAccount = (
  accountId: string,
  params: DocumentFilters,
  queryProps?: QueryOptions<ApiListResp<FormationsDocument[]>>,
): DocumentsQueryResult => {
  const { data, ...rest } = useQuery<
    ApiListResp<FormationsDocument[]>,
    unknown
  >(
    ['documents', 'accountId', accountId, { ...params }],
    () => getDocumentsByAccountId(accountId, params),
    queryProps,
  );
  return {
    documents: data?.data || [],
    pageInfo: data?.pageInfo || defaultApiResponseMetaData,
    ...rest,
  };
};

export const useDocumentsWithCategoryByAccount = (
  { accountId, page, size }: DocumentFilters,
  documentCategory: IDocumentCategory,
) => {
  const { documents: documentData } = useDocumentsByAccount(
    accountId ?? '',
    { page, size },
    {
      enabled: !!accountId,
    },
  );
  return getDocumentAvailableForCategory({
    documents: documentData,
    ...documentCategory,
  });
};

export const useDocument = (
  id: string,
  queryProps?: QueryOptions<FormationsDocument>,
): QueryResult<FormationsDocument> & {
  document: FormationsDocument | undefined;
} => {
  const { data, ...rest } = useQuery<FormationsDocument>(
    ['documents', id],
    () =>
      getDocumentById(id).then((resp) => {
        if (resp) {
          return resp;
        }
        throw new Error('Document not found');
      }),
    queryProps,
  );
  return {
    document: data,
    ...rest,
  };
};

export const useDocuments = (
  ids: string[],
  queryProps?: QueryOptions<FormationsDocument[]>,
): QueryResult<FormationsDocument[]> & {
  documents: FormationsDocument[] | undefined;
} => {
  const { data, ...rest } = useQuery<FormationsDocument[]>(
    ['documents', ids],
    () =>
      Promise.all(
        ids.map((id) =>
          getDocumentById(id).then((resp) => {
            if (resp) {
              return resp;
            }
            throw new Error(`Document with id ${id} not found`);
          }),
        ),
      ),
    queryProps,
  );

  return {
    documents: data,
    ...rest,
  };
};

export const useDocumentForDirectPayment = (
  id: string | undefined,
): QueryResult<FormationsDocument | undefined> & {
  document: FormationsDocument | undefined;
} => {
  const { data, ...rest } = useQuery<FormationsDocument | undefined>(
    ['documents', id],
    () => {
      if (!id) {
        return undefined;
      }
      return getDocumentById(id).then((resp) => {
        if (resp) {
          return resp;
        }
        throw new Error('Document not found');
      });
    },
  );
  return {
    document: data,
    ...rest,
  };
};

export const useDownloadDocument = (
  id: string,
  queryProps?: QueryOptions<Blob>,
): QueryResult<Blob> & {
  blob: Blob | undefined;
} => {
  const { data, ...rest } = useQuery<Blob, unknown>(
    ['downloadDocuments', id],
    () => downloadDocument(id).then((resp) => resp),
    {
      enabled: !!id,
      ...queryProps,
    },
  );

  return {
    blob: data,
    ...rest,
  };
};

export const useDownloadDocumentForDirectPayment = (
  id: string | undefined,
): QueryResult<Blob | undefined> & {
  blob: Blob | undefined;
} => {
  const { data, ...rest } = useQuery<Blob | undefined, unknown>(
    ['downloadDocuments', id],
    () => {
      if (!id) {
        return undefined;
      }
      return downloadDocument(id).then((resp) => resp);
    },
  );

  return {
    blob: data,
    ...rest,
  };
};

export const useDocumentCategories = (
  queryProps?: QueryOptions<DocumentCategory[]>,
): CategoriesQueryResult => {
  const { data, ...rest } = useQuery<DocumentCategory[]>(
    ['documents/categories'],
    () => getDocumentCategories(),
    queryProps,
  );

  return {
    categories: data || [],
    ...rest,
  };
};

export const useDocumentCategory = ({
  department,
  category,
  subcategory,
}: IDocumentCategory) => {
  const { categories, ...rest } = useDocumentCategories();
  if (!categories || categories.length === 0) {
    return {
      category: undefined,
      ...rest,
    };
  }
  const cat = categories.find(
    (c) =>
      c.department === department &&
      c.category === category &&
      c.subcategory === subcategory,
  );

  if (!cat && !rest.isError) {
    return {
      category: undefined,
      ...rest,
      isError: true,
      error: Error('Category not found'),
    };
  }

  return {
    category: cat,
    ...rest,
  };
};

export const useDocumentEmails = (
  queryProps?: QueryOptions<DocumentEmail[]>,
): EmailsQueryResult => {
  const { data: emails, ...rest } = useQuery<DocumentEmail[], unknown>(
    ['documents/emails'],
    () => getDocumentEmails(),
    queryProps,
  );
  return {
    emails: emails || [],
    ...rest,
  };
};

interface CreateDocumentVariables {
  form: CreateDocumentForm;
  sendData?: DirectPaymentFormData;
}
export const useCreateDocument = (
  queryProps?: MutateOptions<
    CreateDocumentData,
    unknown,
    CreateDocumentVariables
  >,
) => {
  const props = useMutation(
    ({ form }: CreateDocumentVariables): Promise<CreateDocumentData> =>
      createDocumentByAccountId(form),
    queryProps,
  );

  return {
    createDocument: props.mutate,
    createDocumentAsync: props.mutateAsync,
    ...props,
  };
};

export interface UpdateDocumentVariables {
  id: string;
  form: UpdateDocumentForm;
}
export const useUpdateDocument = (
  queryProps?: MutateOptions<
    ApiResponse<UpdateDocumentForm>,
    unknown,
    UpdateDocumentVariables
  >,
) =>
  useMutation(
    ({ id, form }: UpdateDocumentVariables) => updateDocument(id, form),
    queryProps,
  );

export const useDeleteDocument = (
  queryProps?: MutateOptions<unknown, unknown, string>,
) => useMutation((id: string) => deleteDocument(id), queryProps);

interface ApproveDocumentVariables {
  id: string;
}
export const useApproveDocument = (
  queryProps?: MutateOptions<unknown, unknown, ApproveDocumentVariables>,
) => useMutation(({ id }) => approveDocument(id), queryProps);

interface RejectDocumentVariables {
  id: string;
  form: { reason: string };
}
export const useRejectDocument = (
  queryProps?: MutateOptions<unknown, unknown, RejectDocumentVariables>,
) =>
  useMutation(
    ({ id, form }: { id: string; form: { reason: string } }) =>
      rejectDocument(id, form),
    queryProps,
  );

export const useDocumentQuery = (): Readonly<
  [query: DocumentFilters, setQuery: SetQuery<QueryParamConfigMap>]
> => {
  const [query, setQuery] = useQueryParams(documentQueryParams);
  return [query, setQuery];
};

// convert date filter from YYYY-MM-DD format to ISO string.
export const transformDocumentQueryParams = (
  params: DocumentFilters,
): DocumentFilters => {
  const from = params.from ? moment(params.from).toISOString() : params.from;
  const to = params.to
    ? moment(params.to).add(1, 'day').toISOString()
    : params.to;
  return {
    ...params,
    from,
    to,
  };
};

export const useDocumentNotes = (documentID: string | undefined) => {
  const { data: notes, ...rest } = useQuery<IDocumentNote[], unknown>(
    ['document-notes', documentID],
    () => listDocumentNote(documentID!),
    { enabled: !!documentID },
  );

  return {
    notes,
    ...rest,
  };
};

export const useCreateDocumentNotes = (
  queryProps?: MutateOptions<unknown, unknown, Partial<IDocumentNote>>,
) =>
  useMutation(
    (params: Partial<IDocumentNote>) => createDocumentNote(params),
    queryProps,
  );

export const useMarkNotesAsRead = (
  queryProps?: MutateOptions<unknown, unknown, string>,
) =>
  useMutation(
    (documentId: string) => markDocumentNoteRead(documentId),
    queryProps,
  );

export const useIncreaseCustomerReadCount = (
  queryProps?: MutateOptions<unknown, unknown, string>,
) =>
  useMutation(
    (documentId: string) => increaseCustomerReadCount(documentId),
    queryProps,
  );
