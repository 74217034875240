import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loading } from 'components/common';
import { ProtectedRoute } from 'auth/ProtectedRoute';
import { Sidebar, SpoofSidebar } from 'layouts/Sidebar';
import { ProgressTrackerLayout } from 'layouts/ProgressTrackerLayout';
import { Onboarding } from 'components/OnboardingV1';
import { FilePreviewPage } from 'components/FilePreview';
import { RedirectToHomeOrLoginPage } from 'components/RedirectToHomeOrLoginPage';
import { RedirectToHomeOrLandingPage } from 'components/RedirectToHomeOrLandingPage';
import { FLAGS, useFeatureFlag } from 'hooks/useFeatureFlag';
import * as Sentry from '@sentry/react';
import { ErrorFallback } from 'components/common/ErrorFallback';
import { FormationsPlan } from 'models/account';
import { Routes as AppRoutes } from './fnRoutes';
import { CONSTANTS } from './constants/common';

const SignUp = lazy(() => import('./views/SignUp'));
const Login = lazy(() => import('./views/Login'));
const PostAuth = lazy(() => import('./views/PostAuth'));

const SignUp1120S = lazy(() => import('./views/SignUp1120S'));
const PostAuth1102S = lazy(() => import('./views/PostAuth1102S'));

const SignUpBIB = lazy(() => import('./views/SignUpBIB'));
const PostAuthBIB = lazy(() => import('./views/PostAuthBIB'));

const LogoutView = lazy(() => import('./views/LogoutView'));
const TermsAndConditions = lazy(
  () => import('./views/termsAndConditions/TermsAndConditions'),
);
const StripePricingTable = lazy(
  () => import('./views/pricingTable/PricingTable'),
);
const VerifyPaymentPage = lazy(
  () => import('./views/pricingTable/VerifyPayment'),
);
const VerifySQSPPaymentPage = lazy(
  () => import('./views/squarespace/VerifySQSPPayment'),
);
const AcceptTerms = lazy(() => import('./views/TermsAndConditions'));
const GustoAuth = lazy(() => import('./views/GustoAuth'));

const Subscription = lazy(() => import('./views/userSubscription'));
const Calculator = lazy(() => import('./views/calculator/index'));
const CalculatorResult = lazy(() => import('./views/calculator/result'));
const XeroConnectionPage = lazy(
  () => import('components/XeroConnection/XeroConnectionPage'),
);
const ChurnZeroAuthentication = lazy(
  () => import('components/ChurnZeroAuthentication'),
);
const FEStep1 = lazy(() => import('views/firstExperience/FEStep1'));
const FEStep2 = lazy(() => import('views/firstExperience/FEStep2'));
const FEStep3 = lazy(() => import('views/firstExperience/FEStep3'));
const FEStep4 = lazy(() => import('views/firstExperience/FEStep4'));
const FEStep5 = lazy(() => import('views/firstExperience/FEStep5'));
const FEPlanSelection = lazy(
  () => import('views/firstExperience/FEPlanSelection'),
);
const PayrollOnboardingFlow = lazy(
  () => import('views/admin/payrollOnboarding/PayrollOnboardingFlow'),
);
const RunPayrollFlow = lazy(() => import('views/admin/RunPayrollFlow'));

const SqspLinkPage = lazy(() => import('views/squarespace/SqspLinkPage'));

const errorFallbackRender = ({
  error,
  componentStack,
  resetError,
}: {
  error: Error;
  componentStack: string | null;
  resetError: () => void;
}) => (
  <ErrorFallback
    error={error}
    componentStack={componentStack}
    resetError={resetError}
  />
);

export const Routing = () => {
  const feedbackFormEnabled = useFeatureFlag(FLAGS.FEEDBACKFORM);

  return (
    <Sentry.ErrorBoundary
      fallback={errorFallbackRender}
      showDialog={feedbackFormEnabled}
    >
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* dashboard routes */}
          <Route
            path={AppRoutes.XERO_CONNECTION}
            element={<XeroConnectionPage />}
          />
          <Route
            path={AppRoutes.DOCUMENT_PREVIEW}
            element={<FilePreviewPage />}
          />
          <Route
            path="/spoof/*"
            element={<ProtectedRoute component={SpoofSidebar} />}
          />
          <Route
            path="/dashboard/*"
            element={<ProtectedRoute component={Sidebar} />}
          />
          <Route
            path={AppRoutes.SQUARESPACE_MANAGEMENT_LINK}
            element={<SqspLinkPage />}
          />
          <Route
            path={AppRoutes.CHURN_ZERO_AUTHENTICATION}
            element={<ProtectedRoute component={ChurnZeroAuthentication} />}
          />
          <Route
            path={AppRoutes.PAYROLL_ONBOARDING_FLOW}
            element={<ProtectedRoute component={PayrollOnboardingFlow} />}
          />
          <Route
            path={AppRoutes.RUN_PAYROLL_FLOW}
            element={<ProtectedRoute component={RunPayrollFlow} />}
          />
          <Route />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/postlogin" element={<PostAuth />} />
          <Route path="/postlogin1120s" element={<PostAuth1102S />} />
          <Route path="/postloginBIB" element={<PostAuthBIB />} />
          <Route path={AppRoutes.FIRST_EXPERIENCE}>
            <Route
              path={AppRoutes.FIRST_EXPERIENCE_STEP_1}
              element={<ProtectedRoute role="Customer" component={FEStep1} />}
            />
            <Route
              path={AppRoutes.FIRST_EXPERIENCE_STEP_2}
              element={<ProtectedRoute role="Customer" component={FEStep2} />}
            />
            <Route
              path={AppRoutes.FIRST_EXPERIENCE_STEP_3}
              element={<ProtectedRoute role="Customer" component={FEStep3} />}
            />
            <Route
              path={AppRoutes.FIRST_EXPERIENCE_STEP_4}
              element={<ProtectedRoute role="Customer" component={FEStep4} />}
            />
            <Route
              path={AppRoutes.FIRST_EXPERIENCE_STEP_5}
              element={<ProtectedRoute role="Customer" component={FEStep5} />}
            />
            <Route
              path={AppRoutes.FIRST_EXPERIENCE_PLAN_SELECTION}
              element={
                <ProtectedRoute role="Customer" component={FEPlanSelection} />
              }
            />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/postsignup/*" element={<PostAuth />} />

          <Route path="/signup1120s" element={<SignUp1120S />} />
          <Route path="/postsignup1120s/*" element={<PostAuth1102S />} />

          <Route path="/signupBIB" element={<SignUpBIB />} />
          <Route path="/postsignupBIB/*" element={<PostAuthBIB />} />

          <Route path={AppRoutes.LOGOUT} element={<LogoutView />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/calculator/result" element={<CalculatorResult />} />
          <Route
            path="/terms"
            element={
              <ProtectedRoute
                component={AcceptTerms}
                role={CONSTANTS.USER_ROLES.CUSTOMER}
              />
            }
          />
          <Route
            path={AppRoutes.TERMS_AND_CONDITIONS}
            element={<TermsAndConditions />}
          />
          <Route
            path={AppRoutes.PRICING_TABLE}
            element={<StripePricingTable />}
          />
          <Route
            path={AppRoutes.VERIFY_PAYMENT}
            element={<VerifyPaymentPage />}
          />
          <Route
            path={AppRoutes.VERIFY_SQSP_PAYMENT}
            element={<VerifySQSPPaymentPage />}
          />
          <Route
            path="/onboarding/:id"
            element={
              <ProtectedRoute
                role={CONSTANTS.USER_ROLES.CUSTOMER}
                component={Onboarding}
              />
            }
          />
          <Route
            path={`${AppRoutes.PROGRESS_TRACKER}/*`}
            element={<ProtectedRoute component={ProgressTrackerLayout} />}
          />
          <Route
            path="/gusto/callback"
            element={<ProtectedRoute role="staff" component={GustoAuth} />}
          />
          <Route
            path="/landing-1120s"
            element={
              <RedirectToHomeOrLandingPage plan={FormationsPlan.TaxOnly} />
            }
          />
          <Route
            path="/landing-BIB"
            element={<RedirectToHomeOrLandingPage plan={FormationsPlan.BIB} />}
          />
          <Route path="*" element={<RedirectToHomeOrLoginPage />} />
        </Routes>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};
