import {
  AssessmentIcon,
  CustomIconComponent,
  ArrowBackIcon,
  AttachMoneyIcon,
  BarChartOutlinedIcon,
  BusinessIcon,
  DateRangeIcon,
  EventIcon,
  FolderOpenIcon,
  GamesIcon,
  InsertLinkIcon as LinkIcon,
  ListAltIcon,
  AccountBalanceIcon,
  AccountBalanceWalletIcon,
  QuizIcon,
  TrendingDownIcon,
  TrendingUpIcon,
  ViewListIcon,
  PaymentsIcon,
} from 'components/common/Icon';
import { SvgIcon } from '@mui/material';
import { Routes } from 'fnRoutes';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import LockClockIcon from '@mui/icons-material/LockClock';
import i18n from 'translations/i18n';
import {
  ACCOUNTING_BUSINESS_HOME_FLAG,
  ACCOUNTING_BUSINESS_MILES_FLAG,
  ACCOUNTING_FLAG,
  ADMIN_BUSINESS_USE_OF_HOME,
  ADMIN_BUSINESSMILES,
  ADMIN_XERO_CONNECTION_HISTORY,
  CHECK_IN_SURVEY_FLAG,
  HURDLR_DATA_SOURCE,
  IRS_MILEAGE_RATE,
  NEW_ONBOARDING_FLAG,
  OIL_CUSTOMER_FLAG,
  PAYROLL_FLAG,
  PAYROLL_ONBOARDING_FLAG,
} from 'hooks/useFeatureFlag';
import { FormationsPlan, IAccount } from 'models/account';
import {
  isAccount1120sOnly,
  isAccountBusinessInABox,
  isAccountHasHurdlrConnected,
  isAccountPayrollEnabled,
} from 'components/ProgressTracker/util';
import { TransactionDataSource } from 'services/features';

// feature flag controls who can view
// actionPermission controls who can click on item
interface IActionBase {
  id: string;
  Icon?: typeof SvgIcon | CustomIconComponent;
  type?: string;
  text: string;
  path: string;
  disabled?: boolean;
  featureFlag?: string; // FLAGS.DEV | FLAGS.BETA | FLAGS.LAUNCH | ''
  actionPermission?: string; // FLAGS.DEV | FLAGS.BETA | FLAGS.LAUNCH | ''
  hiddenForStatus?: string[];
  disabledForStatus?: string[];
  shouldRender?: (account: IAccount) => boolean;
  shouldDisable?: (account: IAccount) => boolean;
}

export type ISubAction = Omit<IActionBase, 'Icon'>;
export interface IAction extends IActionBase {
  subActions?: Array<ISubAction>;
}

export const accountIsAny =
  (arr: Array<(acc?: IAccount) => boolean>) => (account?: IAccount) =>
    arr.some((fn) => fn(account));

const CUSTOMER_ACTIONS: IAction[] = [
  {
    id: 'welcome',
    Icon: BarChartOutlinedIcon,
    text: i18n.t('navigation.dashboard'),
    path: Routes.WELCOME,
  },
  {
    id: 'accounting',
    Icon: ListAltIcon,
    text: i18n.t('navigation.accounting'),
    path: '',
    featureFlag: ACCOUNTING_FLAG,
    disabledForStatus: ['NEW'],
    shouldDisable: accountIsAny([isAccountBusinessInABox]),
    subActions: [
      {
        id: 'bankAccounts',
        text: i18n.t('navigation.bankAccounts'),
        path: Routes.BANK_ACCOUNTS,
        featureFlag: HURDLR_DATA_SOURCE,
        disabledForStatus: ['NEW'],
        shouldDisable: accountIsAny([
          isAccount1120sOnly,
          isAccountBusinessInABox,
        ]),
      },
      {
        id: 'unknownTransactions',
        text: i18n.t('navigation.unknownTransactions'),
        path: Routes.ACCOUNTING_UNKNOWN_TRANSACTIONS,
        featureFlag: ACCOUNTING_FLAG,
        disabledForStatus: ['NEW'],
        shouldDisable: accountIsAny([
          isAccount1120sOnly,
          isAccountBusinessInABox,
        ]),
      },
      {
        id: 'allTransactions',
        text: i18n.t('navigation.allTransactions'),
        path: Routes.ACCOUNTING_ALL_TRANSACTIONS,
        featureFlag: ACCOUNTING_FLAG,
        disabledForStatus: ['NEW'],
        shouldDisable: accountIsAny([
          isAccount1120sOnly,
          isAccountBusinessInABox,
        ]),
      },
      {
        id: 'addTransaction',
        text: i18n.t('navigation.addTransaction'),
        path: Routes.ACCOUNTING_ADD_TRANSACTION,
        featureFlag: ACCOUNTING_FLAG,
        disabledForStatus: ['NEW'],
        shouldDisable: accountIsAny([
          isAccount1120sOnly,
          isAccountBusinessInABox,
        ]),
      },
      {
        id: 'businessMiles',
        text: i18n.t('navigation.businessMiles'),
        path: Routes.ACCOUNTING_BUSINESS_MILES,
        featureFlag: ACCOUNTING_BUSINESS_MILES_FLAG,
        actionPermission: ACCOUNTING_BUSINESS_MILES_FLAG,
        hiddenForStatus: ['NEW'],
        shouldDisable: accountIsAny([isAccountBusinessInABox]),
      },
      {
        id: 'businessUseOfHome',
        text: i18n.t('navigation.businessUseOfHome'),
        path: Routes.ACCOUNTING_BUSINESS_USE_OF_HOME,
        featureFlag: ACCOUNTING_BUSINESS_HOME_FLAG,
        actionPermission: ACCOUNTING_BUSINESS_HOME_FLAG,
        hiddenForStatus: ['NEW'],
        shouldDisable: accountIsAny([isAccountBusinessInABox]),
      },
      {
        id: 'ytdAccounting',
        text: i18n.t('navigation.ytdAccounting'),
        path: Routes.ACCOUNT_YTD,
        shouldRender: (account) =>
          account?.formationsPlan === FormationsPlan.TaxOnly,
      },
      {
        id: 'reports',
        text: i18n.t('navigation.reports'),
        path: Routes.ACCOUNTING_REPORTS,
        hiddenForStatus: ['NEW'],
        featureFlag: TransactionDataSource.Hurdlr,
        shouldRender: accountIsAny([isAccountHasHurdlrConnected]),
      },
    ],
  },
  {
    id: 'payroll',
    Icon: AccountBalanceWalletIcon,
    text: i18n.t('navigation.payroll'),
    path: '',
    featureFlag: PAYROLL_FLAG,
    disabledForStatus: ['NEW'],
    shouldDisable: accountIsAny([isAccount1120sOnly, isAccountBusinessInABox]),
    shouldRender: accountIsAny([isAccountPayrollEnabled]),
    subActions: [
      {
        id: 'payrollSummaries',
        text: i18n.t('navigation.payrollSummaries'),
        path: Routes.PAYROLL,
        featureFlag: PAYROLL_FLAG,
        shouldDisable: accountIsAny([
          isAccount1120sOnly,
          isAccountBusinessInABox,
        ]),
      },
      {
        id: 'payrollSettings',
        text: i18n.t('navigation.payrollSettings'),
        path: Routes.PAYROLL_SETTINGS,
        featureFlag: PAYROLL_FLAG,
        shouldDisable: accountIsAny([
          isAccount1120sOnly,
          isAccountBusinessInABox,
        ]),
      },
    ],
  },
  {
    id: 'taxes',
    Icon: AttachMoneyIcon,
    text: i18n.t('navigation.taxes'),
    path: '',
    disabledForStatus: ['NEW'],
    shouldDisable: accountIsAny([isAccountBusinessInABox]),
    subActions: [
      {
        id: 'oil-list',
        text: i18n.t('navigation.oil'),
        path: Routes.OIL,
        hiddenForStatus: ['NEW'],
        featureFlag: OIL_CUSTOMER_FLAG,
        shouldDisable: accountIsAny([isAccountBusinessInABox]),
      },
      {
        id: 'tax-payments',
        text: i18n.t('navigation.taxPayments'),
        path: Routes.TAXES,
        hiddenForStatus: ['NEW'],
        shouldDisable: accountIsAny([
          isAccount1120sOnly,
          isAccountBusinessInABox,
        ]),
      },
      {
        id: 'tax-form',
        text: i18n.t('navigation.tax-form'),
        path: Routes.TAX_LIABILITY_FORM,
        hiddenForStatus: ['NEW'],
        featureFlag: CHECK_IN_SURVEY_FLAG,
        shouldDisable: accountIsAny([
          isAccount1120sOnly,
          isAccountBusinessInABox,
        ]),
      },
    ],
  },
  {
    id: 'documents',
    Icon: FolderOpenIcon,
    text: i18n.t('navigation.documents'),
    path: Routes.DOCUMENTS,
  },
  {
    id: 'playbook',
    Icon: GamesIcon,
    text: i18n.t('navigation.playbook'),
    path: '',
    hiddenForStatus: ['NEW', 'ACTIVE'],
  },
  {
    id: 'onboarding',
    Icon: ViewListIcon,
    text: i18n.t('navigation.onboarding'),
    path: Routes.ONBOARDING,
    featureFlag: NEW_ONBOARDING_FLAG,
    actionPermission: NEW_ONBOARDING_FLAG,
  },
];

export const CUSTOMER_PAYROLL_SETUP_ACTIONS = CUSTOMER_ACTIONS.map((item) => {
  if (item.id === 'payroll') {
    return {
      id: 'payroll',
      Icon: AccountBalanceWalletIcon,
      text: i18n.t('navigation.payroll'),
      path: Routes.PAYROLL_ONBOARDING,
      featureFlag: PAYROLL_ONBOARDING_FLAG,
    };
  }
  return item;
});

export const CUSTOMER_PAYROLL_DISABLED_ACTIONS = CUSTOMER_ACTIONS.map(
  (item) => {
    if (item.id === 'payroll') {
      return {
        id: 'payroll',
        Icon: AccountBalanceWalletIcon,
        text: 'Payroll',
        path: Routes.PAYROLL,
        featureFlag: PAYROLL_FLAG,
        disabled: true,
      };
    }
    return item;
  },
);

const generateAdminAccountActions = (accountId: string): IAction[] => [
  {
    id: 'accounts',
    Icon: ArrowBackIcon,
    type: 'account',
    text: i18n.t('navigation.accounts'),
    path: Routes.ADMIN_HOME,
  },
  {
    id: 'overview',
    text: i18n.t('navigation.overview'),
    path: '',
    type: 'account',
    subActions: [
      {
        id: 'profile',
        type: 'account',
        text: i18n.t('navigation.profile'),
        path: `/dashboard/accounts/${accountId}/profile`,
      },
      {
        id: 'plan',
        type: 'account',
        text: i18n.t('navigation.plan'),
        path: `/dashboard/accounts/${accountId}/plan`,
      },
      {
        id: 'progress-tracker',
        type: 'account',
        text: i18n.t('navigation.progressTracker'),
        path: `/dashboard/accounts/${accountId}/progress-tracker`,
      },
      {
        id: 'company-set-up',
        type: 'account',
        text: i18n.t('navigation.companySetUp'),
        path: `/dashboard/accounts/${accountId}/company-set-up`,
      },
      {
        id: 'events',
        type: 'account',
        text: i18n.t('navigation.upcomingEvents'),
        path: `/dashboard/accounts/${accountId}/events`,
      },
    ],
  },
  {
    id: 'documents',
    type: 'account',
    text: i18n.t('navigation.documents'),
    path: `/dashboard/accounts/${accountId}/documents`,
  },
  {
    id: 'taxesTop',
    text: i18n.t('navigation.taxes'),
    path: '',
    type: 'account',
    subActions: [
      {
        id: 'taxLiability',
        type: 'account',
        text: i18n.t('navigation.taxLiability'),
        path: `/dashboard/accounts/${accountId}/taxLiability`,
      },
      {
        id: 'open-item-list',
        type: 'account',
        text: i18n.t('navigation.oil'),
        path: `/dashboard/accounts/${accountId}/open-item-list`,
      },
      {
        id: 'tax-filing',
        type: 'account',
        text: i18n.t('navigation.taxFiling'),
        path: `/dashboard/accounts/${accountId}/tax-filing`,
      },
      {
        id: 'taxes',
        type: 'account',
        text: i18n.t('navigation.taxPayments'),
        path: `/dashboard/accounts/${accountId}/taxes`,
      },
      {
        id: 'checkin',
        type: 'account',
        text: i18n.t('navigation.checkIn'),
        path: `/dashboard/accounts/${accountId}/checkin`,
      },
    ],
  },
  {
    id: 'accountingTop',
    text: i18n.t('navigation.accounting'),
    path: '',
    type: 'account',
    subActions: [
      {
        id: 'hurdlr',
        type: 'account',
        text: i18n.t('navigation.hurdlr'),
        path: `/dashboard/accounts/${accountId}/hurdlr`,
      },
      {
        id: 'businessUseOfHome',
        type: 'account',
        text: i18n.t('navigation.businessUseOfHome'),
        path: `/dashboard/accounts/${accountId}/businessUseOfHome`,
        featureFlag: ADMIN_BUSINESS_USE_OF_HOME,
      },
      {
        id: 'businessMiles',
        type: 'account',
        text: i18n.t('navigation.businessMiles'),
        path: `/dashboard/accounts/${accountId}/businessMiles`,
        featureFlag: ADMIN_BUSINESSMILES,
      },
      {
        id: 'xeroConnectionHistory',
        type: 'account',
        text: i18n.t('navigation.xeroConnectionHistory'),
        path: `/dashboard/accounts/${accountId}/xeroConnectionHistory`,
        featureFlag: ADMIN_XERO_CONNECTION_HISTORY,
      },
      {
        id: 'account-ytd',
        type: 'account',
        text: i18n.t('navigation.ytdAccounting'),
        path: `/dashboard/accounts/${accountId}/account-ytd`,
      },
    ],
  },
  {
    id: 'payrollTop',
    text: i18n.t('navigation.payroll'),
    path: '',
    type: 'account',
    subActions: [
      {
        id: 'payroll',
        type: 'account',
        text: i18n.t('navigation.payroll'),
        path: `/dashboard/accounts/${accountId}/payroll`,
      },
      {
        id: 'officers',
        type: 'account',
        text: i18n.t('navigation.officers'),
        path: `/dashboard/accounts/${accountId}/officers`,
      },
    ],
  },
];

const ADMIN_ACTIONS = [
  {
    id: 'accounts',
    Icon: AccountBalanceWalletIcon,
    text: i18n.t('navigation.accounts'),
    path: Routes.ADMIN_HOME,
  },
  {
    id: 'hurdlr',
    Icon: AccountBalanceIcon,
    text: i18n.t('navigation.hurdlr'),
    path: Routes.HURDLR,
  },
  {
    id: 'worknet',
    Icon: AutoAwesomeRoundedIcon,
    text: i18n.t('navigation.worknet'),
    path: Routes.WORKNET,
  },
  {
    id: 'upcomingEvents',
    Icon: EventIcon,
    text: i18n.t('navigation.upcomingEvents'),
    path: Routes.UPCOMING_EVENTS,
  },
  {
    id: 'taxBracket',
    Icon: TrendingUpIcon,
    text: i18n.t('navigation.taxBracket'),
    path: Routes.TAX_BRACKET,
  },
  {
    id: 'standardDeduction',
    Icon: TrendingDownIcon,
    text: i18n.t('navigation.standardDeduction'),
    path: Routes.STANDARD_DEDUCTION,
  },
  {
    id: 'industryOptions',
    Icon: BusinessIcon,
    text: i18n.t('navigation.industryOptions'),
    path: Routes.INDUSTRY_OPTIONS,
  },
  {
    id: 'payrollDeductions',
    Icon: AttachMoneyIcon,
    text: i18n.t('navigation.payrollDeductions'),
    path: Routes.PAYROLL_DEDUCTIONS,
  },
];

const SUPERADMIN_ACTIONS = [
  {
    id: 'irs',
    Icon: AssessmentIcon,
    text: i18n.t('navigation.irsMileageRate'),
    path: Routes.SUPERADMIN_IRS_RATE,
    featureFlag: IRS_MILEAGE_RATE,
  },
  {
    id: 'csmMeetingLink',
    Icon: LinkIcon,
    text: i18n.t('navigation.csmMeetingLink'),
    path: Routes.CSM_MEETING_LINK,
  },
  {
    id: 'activeSurvey',
    Icon: QuizIcon,
    text: i18n.t('navigation.activeSurvey'),
    path: Routes.ACTIVE_SURVEY,
  },
  {
    id: 'activeTaxYears',
    Icon: DateRangeIcon,
    text: i18n.t('navigation.activeTaxYears'),
    path: Routes.ACTIVE_TAX_YEARS,
  },
  {
    id: 'taxDeadline',
    Icon: LockClockIcon,
    text: i18n.t('navigation.taxDeadline'),
    path: Routes.TAX_DEADLINE,
  },
  {
    id: 'taxLiability',
    Icon: PaymentsIcon,
    text: i18n.t('navigation.taxLiability'),
    path: Routes.TAX_LIABILITY,
  },
];

export {
  CUSTOMER_ACTIONS,
  generateAdminAccountActions,
  ADMIN_ACTIONS,
  SUPERADMIN_ACTIONS,
};
