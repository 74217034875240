import { Box, Grid, Typography } from '@mui/material';
import { PersonFilledIcon } from 'components/common/Icon';
import LinearProgress from '@mui/material/LinearProgress';
import {
  useCompanySetupState,
  useCurrentAccount,
  useCurrentCompany,
  useCurrentUserRole,
  useNavigate,
} from 'hooks/api';
import { useMemo } from 'react';
import { CompanySetupState } from 'models/company';
import { Routes } from 'fnRoutes';
import { isAccountFullSuite } from 'components/ProgressTracker/util';

const TotalSteps = 13;
export const getProgressStepCount = (
  companySetupState?: CompanySetupState,
  isDisabledPayroll: boolean = false,
) => {
  if (!companySetupState) return 0;

  const payrollSteps = [
    'directDepositInformation',
    'reasonableCompensationStudy',
    'payrollOnboarding',
    'payrollPlanApproval',
  ];
  const steps = [
    'businessConfiguration',
    'shareableBusinessPacket',
    'personalProfile',
    'businessProfile',
    'lastYearTaxReturn',
    'yearToDateCatchUp',
    'bankConnections',
    'businessUseOfHome',
    ...payrollSteps,
    'squarespaceAddOn',
  ];

  return steps.reduce((count, step) => {
    if (companySetupState[step as keyof CompanySetupState]) {
      if (isDisabledPayroll && payrollSteps.includes(step)) {
        return count;
      }
      return count + 1;
    }
    return count;
  }, 0);
};

export const getTotalSteps = (isDisabledPayroll: boolean) =>
  isDisabledPayroll ? TotalSteps - 4 : TotalSteps;

export const SetupProgress = () => {
  const navigate = useNavigate();
  const { currentAccount } = useCurrentAccount();
  const { currentCompany } = useCurrentCompany();
  const { companySetupState } = useCompanySetupState(currentCompany?.id);
  const { isAdmin } = useCurrentUserRole();
  const total = getTotalSteps(currentAccount?.disabledPayroll ?? false);
  const progressCount = useMemo(
    () =>
      getProgressStepCount(
        companySetupState,
        currentAccount?.disabledPayroll ?? false,
      ),
    [companySetupState, currentAccount?.disabledPayroll],
  );
  const showSetupProgress = useMemo(() => {
    if (isAdmin) return false;
    if (currentAccount?.status?.label !== 'ACTIVE') return false;
    if (!isAccountFullSuite(currentAccount)) return false;
    return progressCount < total;
  }, [progressCount, currentAccount?.status?.label, isAdmin, total]);

  return showSetupProgress ? (
    <Grid
      container
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      sx={{ cursor: 'pointer', p: 2 }}
      onClick={() => {
        navigate(Routes.SETUP_PAGE);
      }}
    >
      <PersonFilledIcon />
      <Grid
        item
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ ml: 2, flexGrow: 1, pr: 2 }}
      >
        <Typography variant="body2S" color="text.secondary" sx={{ mb: 1 }}>
          Complete set up
        </Typography>
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant="determinate"
            value={(progressCount / total) * 100}
          />
        </Box>
        <Typography variant="body3B" sx={{ mt: 1 }}>
          {progressCount}/{total} completed
        </Typography>
      </Grid>
    </Grid>
  ) : null;
};
