import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CHURN_ZERO_EVENT_NAMES, NOTE_MAX_LENGTH } from 'constants/common';
import {
  FormationsPrimaryButton,
  FormationsGhostButton,
} from 'components/common/Buttons';
import { Spacer } from 'components/Spacer';
import {
  useCreateDocument,
  useCreateDocumentNotes,
  useCurrentAccount,
} from 'hooks/api';
import { sendChurnZeroEvent } from 'helpers/churnZero';
import { queryClient } from 'states/reactQueryClient';
import { showErrorToast, showSuccessToast } from 'components/toast/showToast';
import {
  FormationsFormActions,
  FormationsFormFields,
  IFormField,
  renderField,
} from 'components/common/FormationsForm2';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'translations/i18n';
import { CreateDocumentForm } from 'services/documentTypes';
import { DOCUMENT_HUB_NOTE_FLAG, useFeatureFlag } from 'hooks/useFeatureFlag';
import { TempDocument } from '../types';

interface IEditDocumentDialog {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (documentId: string) => void;
  file: TempDocument;
  additionalProps?: Partial<CreateDocumentForm>;
}

const schema = yup.object().shape({
  documentName: yup.string().nullable().required('Please enter a name'),
  documentNote: yup
    .string()
    .nullable()
    .max(
      NOTE_MAX_LENGTH,
      `Please limit note input to ${NOTE_MAX_LENGTH} characters.`,
    ),
});

const documentName: IFormField = {
  name: 'documentName',
  type: FormationsFormFields.Text,
  label: 'File Name',
  options: {
    autoFocus: true,
    width: '100%',
  },
};

const documentNote: IFormField = {
  name: 'documentNote',
  type: FormationsFormFields.TextArea,
  label: '',
  options: {
    placeholder: i18n.t('documentHub.uploadDocument.notePlaceHolder'),
    characterLimit: NOTE_MAX_LENGTH,
    width: '100%',
  },
};

export const EditDocumentDialog = ({
  isOpen,
  onClose,
  onSuccess,
  file,
  additionalProps,
}: IEditDocumentDialog) => {
  const { t } = useTranslation();
  const { currentAccount } = useCurrentAccount();
  const enableNote = useFeatureFlag(DOCUMENT_HUB_NOTE_FLAG);
  const accountId = currentAccount?.id || '';
  const companyId = currentAccount?.companyId || '';
  const { mutateAsync: createDocument, isLoading: isDocumentCreating } =
    useCreateDocument({
      onSuccess: async () => {
        sendChurnZeroEvent(CHURN_ZERO_EVENT_NAMES.DOCUMENT_UPLOAD);
        await queryClient.invalidateQueries([
          'documents',
          'accountId',
          accountId,
        ]);
      },
      onError: () => {
        showErrorToast(
          `Unable to upload document, request timed out. Please check your internet connection and try again.`,
        );
      },
    });
  const { mutateAsync: createDocumentNote, isLoading: updatingDocumentNote } =
    useCreateDocumentNotes({
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'documents',
          'accountId',
          accountId,
        ]);
      },
    });

  const formInstance = useForm({
    defaultValues: {
      documentName: file.displayName,
      documentNote: undefined,
      mode: 'onChange',
      reValidateMode: 'onChange',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });
  const { handleSubmit, formState } = formInstance;

  const handleSubmitClick = async (data: {
    documentName: string;
    documentNote?: string;
  }) => {
    try {
      const resDocument = await createDocument({
        form: {
          ...additionalProps,
          accountId: additionalProps?.accountId ?? accountId,
          file: file.file,
          title: data.documentName + file.extension,
          year: additionalProps?.year ?? new Date().getFullYear(),
          companyId,
        },
      });
      const note = data.documentNote?.trim();
      if (note) {
        await createDocumentNote({
          note,
          accountId,
          documentId: resDocument.data.id,
        });
      }
      showSuccessToast(t('documentHub.uploadDocument.successMessage'));
      onSuccess(resDocument.data.id);
    } catch (e: unknown) {
      if (e instanceof Error) {
        showErrorToast(e.message);
      } else {
        showErrorToast('An error occurred while uploading file.');
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      data-testid="upload-document-dialog"
    >
      <form noValidate onSubmit={handleSubmit(handleSubmitClick)}>
        <DialogTitle sx={{ p: 3 }}>
          {t('documentHub.uploadDocument.title')}
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body2S"
            color="text.secondary"
            sx={{ mb: 2, pb: 2 }}
          >
            {t('documentHub.uploadDocument.subtitle')}
          </Typography>
          <Spacer height={2} />
          <Grid item key="documentName" xs={12} sx={{ my: 0, pt: 1 }}>
            {renderField(documentName, formInstance)}
          </Grid>
          {enableNote && (
            <>
              <Spacer height={2} />
              <Typography
                variant="body2S"
                color="text.secondary"
                sx={{ mb: 2, pb: 2 }}
              >
                {t('documentHub.uploadDocument.noteTitle')}
              </Typography>
              <Grid item key="documentNote" xs={12} sx={{ my: 0, pt: 1 }}>
                {renderField(documentNote, formInstance)}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <FormationsFormActions sx={{ m: 0 }}>
            <>
              <FormationsGhostButton
                size="large"
                onClick={onClose}
                data-testid="form-cancel-btn"
                disabled={isDocumentCreating || updatingDocumentNote}
                sx={{ mr: 2 }}
              >
                {t('actions.back')}
              </FormationsGhostButton>
              <FormationsPrimaryButton
                isLoading={isDocumentCreating || updatingDocumentNote}
                size="large"
                type="submit"
                data-testid="form-save-btn"
                disabled={!formState.isValid}
              >
                {t('actions.upload')}
              </FormationsPrimaryButton>
            </>
          </FormationsFormActions>
        </DialogActions>
      </form>
    </Dialog>
  );
};
