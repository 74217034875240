import { ApiResponse } from 'models/api';
import { api } from './axios';

export enum DropboxSignTemplates {
  'LLCOnlySignature' = 'LLCOnlySignature',
  'SingleOwnerNewEntitySCorpSignature' = 'SingleOwnerNewEntitySCorpSignature',
  'SingleOwnerNewEntityNoSCorpSignature' = 'SingleOwnerNewEntityNoSCorpSignature',
  'SingleOwnerExistingSCorpSignature' = 'SingleOwnerExistingSCorpSignature',
  'DualOwnerNewEntitySCorpSignature' = 'DualOwnerNewEntitySCorpSignature',
  'DualOwnerNewEntityNoSCorpSignature' = 'DualOwnerNewEntityNoSCorpSignature',
  'DualOwnerExistingSCorpSignature' = 'DualOwnerExistingSCorpSignature',
}

export const DropboxSignTemplateURLs: Record<DropboxSignTemplates, string> = {
  [DropboxSignTemplates.LLCOnlySignature]:
    'https://formations-public-docs.s3.us-west-2.amazonaws.com/SMLLC+Signature+Packet.pdf',
  [DropboxSignTemplates.SingleOwnerNewEntitySCorpSignature]:
    'https://formations-public-docs.s3.us-west-2.amazonaws.com/01+Signature+Packet+Single+Owner+New+Entity+to+S-Corp.pdf',
  [DropboxSignTemplates.SingleOwnerNewEntityNoSCorpSignature]:
    'https://formations-public-docs.s3.us-west-2.amazonaws.com/02+Signature+Packet+-+Single+Owner+New+Entity+(No+S-Corp+Election).pdf',
  [DropboxSignTemplates.SingleOwnerExistingSCorpSignature]:
    'https://formations-public-docs.s3.us-west-2.amazonaws.com/03+Signature+Packet+Single+Owner+Existing+S-Corp.pdf',
  [DropboxSignTemplates.DualOwnerNewEntitySCorpSignature]:
    'https://formations-public-docs.s3.us-west-2.amazonaws.com/04+Signature+Packet+Dual+Owner+New+Entity+to+S-Corp.pdf',
  [DropboxSignTemplates.DualOwnerNewEntityNoSCorpSignature]:
    'https://formations-public-docs.s3.us-west-2.amazonaws.com/05+Signature+Packet+-+Dual+Owner+New+Entity+(No+S-Corp+Election).pdf',
  [DropboxSignTemplates.DualOwnerExistingSCorpSignature]:
    'https://formations-public-docs.s3.us-west-2.amazonaws.com/06+Signature+Packet+-+Dual+Owner+Existing+S-+Corp.pdf',
};

export const getDropboxSignTemplateURL = (
  template: DropboxSignTemplates,
): string => DropboxSignTemplateURLs[template];

export type HelloSignTemplateSignature = {
  signature_id: string;
  signer_email_address: string;
  signer_name: string;
  signer_role: string;
  order: number;
  status_code: string;
  signed_at: string;
  last_viewed_at: string;
  last_reminded_at: string;
  has_pin: boolean;
  has_sms_auth: boolean;
};

export type HelloSignSignature = {
  sign_url: string;
  expires_at: string;
};

export type HelloSignTemplate = {
  signature_request_id: string;
  title: string;
  original_title: string;
  subject: string;
  metadata: any;
  message: string;
  is_complete: boolean;
  is_declined: boolean;
  has_error: boolean;
  custom_fields: any[];
  response_data: any[];
  signing_url: string;
  signing_redirect_url: string;
  details_url: string;
  requester_email_address: string;
  signatures: HelloSignTemplateSignature[];
};

export type HelloSignSignedDocument = {
  id: string;
  signatureRequestTemplate: DropboxSignTemplates;
  signatureRequestId: string;
  accountId: string;
  documentId?: string;
  signedAt: string;
};

export const createHelloSignTemplate = async (
  accountId: string,
  templateName: DropboxSignTemplates,
): Promise<HelloSignTemplate> => {
  const { data } = await api.post<
    {
      templateName: DropboxSignTemplates;
    },
    ApiResponse<HelloSignTemplate>
  >(`dropbox-sign/${accountId}/create-embedded-with-template`, {
    templateName,
  });
  return data;
};

export const getHelloSignSignatureURL = async (
  accountId: string,
  signatureId: string,
): Promise<HelloSignSignature> => {
  const { data } = await api.get<HelloSignSignature>(
    `dropbox-sign/${accountId}/embedded-signature-url/${signatureId}`,
  );
  return data;
};

export const getSignedDocument = async (
  template?: DropboxSignTemplates,
  accountId?: string,
): Promise<HelloSignSignedDocument[]> => {
  const { data } = await api.get<HelloSignSignedDocument[]>(
    `/dropbox-sign/${accountId}/signed-documents?template=${template}`,
  );
  return data;
};

export const saveHelloSignDocument = async (
  accountId: string,
  signatureRequestId: string,
  templateName: DropboxSignTemplates,
): Promise<HelloSignSignedDocument> => {
  const { data } = await api.post<
    {
      templateName: DropboxSignTemplates;
    },
    ApiResponse<HelloSignSignedDocument>
  >(`/dropbox-sign/${accountId}/save-signed-document/${signatureRequestId}`, {
    templateName,
  });
  return data;
};

export const overrideSignedDocument = async (
  accountId: string,
  documentId: string,
  templateName: DropboxSignTemplates,
): Promise<HelloSignSignedDocument> => {
  const { data } = await api.post<
    {
      documentId: string;
      templateName: DropboxSignTemplates;
    },
    ApiResponse<HelloSignSignedDocument>
  >(`/dropbox-sign/${accountId}/signed-documents`, {
    documentId,
    templateName,
  });
  return data;
};
