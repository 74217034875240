import { styled } from '@mui/styles';
import { Chip } from '@mui/material';

export const Tag = styled(Chip)(({ theme }) => ({
  fontSize: '10px',
  lineHeight: '10px',
  fontWeight: 'bold',
  padding: '2px 8px',
  margin: theme.spacing(0, 1),
  textTransform: 'uppercase',
  borderRadius: '5px',
  height: '24px',
  '& .MuiChip-label': {
    padding: 0,
    transform: 'translateY(1px)',
  },
  '&.MuiChip-outlinedWarning': {
    borderColor: theme.palette.warning.dark,
    color: theme.palette.warning.dark,
  },
}));
