import React, { useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Typography,
  DialogProps,
} from '@mui/material';
import {
  FormationsPrimaryButton,
  FormationsDangerButton,
  FormationsGhostButton,
} from 'components/common/Buttons';
import styled from '@emotion/styled';
import { theme as MuiTheme } from 'theme';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      minWidth: 250,
      maxWidth: 250,
    },
  },
}));

const DialogContent = styled(MuiDialogContent)({
  padding: MuiTheme.spacing(0, 0, 4, 0),
});

const DialogActions = styled(MuiDialogActions)({
  display: 'flex',
  padding: MuiTheme.spacing(0),
});

interface ButtonProps {
  okText?: string;
  cancelText?: string;
  isValid?: boolean;
  onSave?: (() => void) | (() => Promise<void>);
  onClose?: () => void;
  cancelButtonStyle?: 'primary' | 'ghost';
  okButtonStyle?: 'primary' | 'error';
  isLoading?: boolean;
  handleClose?: () => void | Promise<void>;
  handleSave?: () => void;
}

export const YesNoButtons = ({
  okText = 'Save Changes',
  cancelText = 'Cancel',
  isValid = true,
  onSave,
  onClose,
  cancelButtonStyle = 'ghost',
  okButtonStyle = 'primary',
  isLoading = false,
  handleClose,
  handleSave,
}: ButtonProps) => (
  <>
    {onClose &&
      (cancelButtonStyle === 'primary' ? (
        <FormationsPrimaryButton
          data-testid="confirm-cancel-btn"
          size="large"
          onClick={handleClose}
        >
          {cancelText}
        </FormationsPrimaryButton>
      ) : (
        <FormationsGhostButton
          data-testid="confirm-cancel-btn"
          size="large"
          onClick={handleClose}
        >
          {cancelText}
        </FormationsGhostButton>
      ))}

    {onSave &&
      (okButtonStyle === 'error' ? (
        <FormationsDangerButton
          isLoading={isLoading}
          size="large"
          disabled={!isValid}
          onClick={handleSave}
          data-testid="confirm-ok-btn"
        >
          {okText}
        </FormationsDangerButton>
      ) : (
        <FormationsPrimaryButton
          isLoading={isLoading}
          size="large"
          disabled={!isValid}
          onClick={handleSave}
          data-testid="confirm-ok-btn"
        >
          {okText}
        </FormationsPrimaryButton>
      ))}
  </>
);

interface Props extends DialogProps {
  open: boolean;
  okText?: string;
  cancelText?: string;
  heading: React.ReactNode;
  isValid?: boolean;
  onSave?: (() => void) | (() => Promise<void>);
  onClose?: () => void;
  cancelButtonStyle?: 'primary' | 'ghost';
  okButtonStyle?: 'primary' | 'error';
  children?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
}

export function YesNoModal({
  open,
  children = null,
  okText = 'Save Changes',
  cancelText = 'Cancel',
  heading,
  isValid = true,
  onSave,
  onClose,
  cancelButtonStyle = 'ghost',
  okButtonStyle = 'primary',
  size = 'sm',
  isLoading = false,
  ...rest
}: Readonly<Props>) {
  const classes = useStyles();

  const handleSave = useCallback(() => onSave?.(), [onSave]);
  const handleClose = useCallback(() => onClose?.(), [onClose]);
  const sizesMap = {
    sm: 430,
    md: 600,
    lg: 900,
  };

  return (
    <Dialog
      classes={{
        paper: `${classes.dialog}`,
      }}
      onClose={handleClose}
      open={open}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: `${sizesMap[size]}px`,
        },
      }}
      {...rest}
    >
      <DialogContent>
        {typeof heading === 'string' ? (
          <Typography variant="h6B" component="h6" sx={{ mb: 1 }}>
            {heading}
          </Typography>
        ) : (
          heading
        )}

        {children}
      </DialogContent>
      <DialogActions>
        <YesNoButtons
          onClose={onClose}
          onSave={onSave}
          handleClose={handleClose}
          handleSave={handleSave}
          okText={okText}
          cancelText={cancelText}
          isValid={isValid}
          isLoading={isLoading}
          okButtonStyle={okButtonStyle}
          cancelButtonStyle={cancelButtonStyle}
        />
      </DialogActions>
    </Dialog>
  );
}
