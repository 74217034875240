import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Grid,
  Typography,
} from '@mui/material';
import { ExpandMoreIcon } from 'components/common/Icon';
import { useActivateAccount, useCurrentAccount } from 'hooks/api/useAccounts';
import {
  useCurrentCompany,
  useCurrentCoverLetter,
  useUpdateCompanySetupStateAsync,
  useUpdateCoverLetter,
} from 'hooks/api/useCompanies';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAccount } from 'services/account';
import { Company } from 'services/companies';
import {
  FormationsPrimaryButton,
  FormationsTertiaryButton,
} from 'components/common/Buttons';
import { useCurrentUser, useNavigate } from 'hooks/api';
import { queryClient } from 'states/reactQueryClient';
import { showErrorToast, showToastOnError } from 'components/toast/showToast';
import { Routes } from 'fnRoutes';
import i18n from 'translations/i18n';
import { ALLOWED_BANKS } from 'enums';
import { capitalizeFirstLetter } from 'helpers/text-transformer';
import { FormationsPlan } from 'models/account';
import { StageSkeleton } from '../StageSkeleton';
import { AccordionCTA } from '../AccordionCTA';
import { BusinessPackageView } from './BusinessPackageView';

interface AccountVerificationComponentProps {
  companyData: Company;
  account: IAccount;
  expanded: boolean | string;
  handleExpand: (
    panel: string,
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  disabled: boolean;
}

const initHeader =
  '[Your Full Name]\n123 Fake Street\nAnytown, Anystate 12345\nPhone: (123) 456-7890\nEmail: yourname@mail.com';
const initContent = i18n.t(
  'progressTracker.businessPackage.content_placeholder',
);
const initBankingInfo = i18n.t(
  'progressTracker.businessPackage.banking_info_placeholder',
);

export const BusinessPackage = ({
  companyData,
  account: accData,
  expanded,
  handleExpand,
  disabled,
}: AccountVerificationComponentProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [header, setHeader] = useState<string>(initHeader);
  const [content, setContent] = useState<string>(initContent);
  const [bankInfo, setBankInfo] = useState<string>(initBankingInfo);

  const { currentUser } = useCurrentUser();
  const { activateAccountAsync: activate, isLoading: updatingAccount } =
    useActivateAccount(accData.id);
  const {
    updateCompanySetupStateAsync,
    isLoading: isCompanySetupStateUpdating,
  } = useUpdateCompanySetupStateAsync();
  const { workPhone, mailingAddress } = companyData.contactDetails! ?? {};

  const { coverLetter } = useCurrentCoverLetter();
  const { updateCoverLetterAsync, isLoading: updatingCoverLetter } =
    useUpdateCoverLetter({
      onSuccess: () => {
        queryClient.invalidateQueries('companyCoverLetter');
      },
    });

  const isLoading =
    isCompanySetupStateUpdating || updatingCoverLetter || updatingAccount;

  useEffect(() => {
    if (currentUser && mailingAddress && workPhone && !coverLetter) {
      setHeader(
        t('progressTracker.businessPackage.header_placeholder', {
          name: `${currentUser.name?.first} ${currentUser.name?.last}`,
          address: `${mailingAddress?.street1}${
            mailingAddress?.street2 ? `\n${mailingAddress?.street2}` : ''
          }`,
          city: mailingAddress?.city,
          state: mailingAddress?.state,
          zip: mailingAddress?.zip,
          phone: workPhone,
          email: currentUser.email,
        }),
      );
      const bankNameKey = companyData?.bankName ?? '';
      let bankName = '';
      if (bankNameKey === 'other') {
        bankName = companyData.bankName || '';
      } else {
        bankName =
          ALLOWED_BANKS[bankNameKey] || capitalizeFirstLetter(bankNameKey);
      }
      setBankInfo(
        t('progressTracker.businessPackage.banking_info_placeholder', {
          bankName,
          accountNumber: companyData?.bankAccountNumber,
          routingNumber: companyData?.routingNumber,
        }),
      );
    } else if (coverLetter) {
      setHeader(coverLetter.title);
      setContent(coverLetter.body);
      setBankInfo(coverLetter.bankInfo);
    }
  }, [
    currentUser,
    workPhone,
    mailingAddress,
    coverLetter,
    companyData?.bankName,
    companyData?.routingNumber,
    companyData?.bankAccountNumber,
    t,
  ]);

  const handleGetStarted = async () => {
    try {
      await activate();
      await queryClient.invalidateQueries(['dropboxSignedDocuments']);
      await queryClient.invalidateQueries('currentAccount');
      await queryClient.invalidateQueries(['account', accData.id]);
      await queryClient.invalidateQueries(['progressTracker', accData.id]);
      if (accData.formationsPlan === FormationsPlan.BIB) {
        navigate(Routes.HOME());
      } else {
        navigate(Routes.SETUP_PAGE);
      }
    } catch (e) {
      showToastOnError(e);
    }
  };

  const handleSaveAndContinue = async () => {
    try {
      await updateCoverLetterAsync({
        companyId: companyData.id!,
        data: {
          companyId: companyData.id!,
          title: header,
          body: content,
          bankInfo,
        },
      });
      await updateCompanySetupStateAsync({
        businessConfiguration: true,
        shareableBusinessPacket: true,
      });
      await handleGetStarted();
    } catch (e) {
      showErrorToast('Error updating cover letter');
    } finally {
      handleExpand('business-package');
    }
  };

  const handleSkip = async () => {
    handleExpand('business-package');
    await updateCompanySetupStateAsync({
      businessConfiguration: true,
    });
    await handleGetStarted();
  };

  return (
    <Accordion
      expanded={expanded === 'business-package'}
      onChange={handleExpand('business-package')}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="business-package-content"
        id="business-package-header"
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6B">
              {t('progressTracker.businessPackage.title')}
            </Typography>
            <Typography variant="subtitle1">
              {t('progressTracker.businessPackage.subtitle')}
            </Typography>
            <AccordionCTA
              expanded={expanded}
              handleExpand={handleExpand}
              completed={0}
              totalStep={1}
              testId="start-business-package"
              accordionId="business-package"
            />
          </Grid>
        </Grid>
        {!expanded && (
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
            <Typography variant="h8B">
              {0}/{1}
            </Typography>
          </Grid>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <BusinessPackageView
          header={header}
          content={content}
          bankInfo={bankInfo}
          setHeader={setHeader}
          setContent={setContent}
          setBankInfo={setBankInfo}
        />
        <Grid
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 2 }}
        >
          <FormationsTertiaryButton
            onClick={handleSkip}
            isLoading={isLoading}
            disabled={isLoading}
          >
            {accData.formationsPlan !== FormationsPlan.BIB
              ? t('progressTracker.businessPackage.skipFullsuiteCustomer')
              : t('progressTracker.businessPackage.skip')}
          </FormationsTertiaryButton>

          <FormationsPrimaryButton
            onClick={handleSaveAndContinue}
            isLoading={isLoading}
            disabled={isLoading}
          >
            {t('progressTracker.businessPackage.cta')}
          </FormationsPrimaryButton>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

interface BusinessPackageComponentProps {
  expanded: boolean | string;
  handleExpand: (
    panel: string,
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  disabled: boolean;
}

export const BusinessPackageComponent: React.FC<
  BusinessPackageComponentProps
> = ({ expanded, handleExpand, disabled }) => {
  const {
    currentCompany: companyData,
    isLoading: companyDataLoading,
    isError: isCompanyDataError,
  } = useCurrentCompany();
  const {
    currentAccount,
    isLoading: fetchingCurrentAccount,
    isError: isAccountError,
  } = useCurrentAccount();

  if (fetchingCurrentAccount || companyDataLoading) {
    return <StageSkeleton />;
  }
  if (companyData != null && currentAccount != null) {
    return (
      <BusinessPackage
        companyData={companyData}
        account={currentAccount}
        handleExpand={handleExpand}
        expanded={expanded}
        disabled={disabled}
      />
    );
  }
  if (isCompanyDataError && isAccountError) {
    return (
      <Alert severity="error">
        Error fetching data. Please try to refresh or contact for support
      </Alert>
    );
  }
  return null;
};
