import { Box, Grid, ChipProps, Dialog } from '@mui/material';
import { PrimaryChip, PrimaryChipBadge } from 'components/common/PrimaryChip';
import { FormationsGhostButton } from 'components/common/Buttons';
import { isMatch, omit, isEqual } from 'lodash';
import {
  FilterItem,
  GenericQuickFilter,
} from 'components/common/GenericQuickFilter/GenericQuickFilter';
import { useCurrentUserRole } from 'hooks/api';
import { queryClient } from 'states/reactQueryClient';
import { PaymentType, PaymentStatus, PaymentSource } from 'enums';
import { TaxesFilters, useTaxesQuery } from 'hooks/api/useTaxes';
import { FilterListIcon, RefreshIcon } from 'components/common/Icon';
import { useMemo, useState } from 'react';
import { TaxesMobileFilter } from './TaxesMobileFilter';

interface Props extends ChipProps {
  label: string;
  selected?: boolean;
  count?: number;
  onClick: () => void;
}

const chipButtonStyle = (isFilled: boolean) => ({
  '@media (max-width: 600px)': { // This is to override the default MUI hover effect on phones to target IOS focus behavior
    '&.MuiChip-clickable:hover': {
      backgroundColor: isFilled ? '#003CB2' : 'transparent', // Masking hover effect because IOS re-hovers after button click
      '& .MuiChip-label, & .MuiChip-icon': {
        color: isFilled ? 'white' : 'black',
      },
      borderColor: isFilled ? 'transparent' : 'black',
    },
  },
});

export const QuickFilter = ({
  label,
  onClick,
  count = 0,
  selected = false,
  ...rest
}: Props) => {
  const chip = (
    <PrimaryChip
      label={label}
      variant={selected ? 'filled' : 'outlined'}
      onClick={onClick}
      sx={chipButtonStyle(selected)}
      {...rest}
    />
  );
  return count > 0 ? (
    <PrimaryChipBadge
      data-testid="quick-filter-badge"
      badgeContent={count}
    >
      {chip}
    </PrimaryChipBadge>
  ) : (
    chip
  );
};

export const defaultTaxesFilter: TaxesFilters = {
  page: 1,
};

export const emptyTaxesFilter: TaxesFilters = {
  years: undefined,
  type: undefined,
  source: undefined,
  status: undefined,
  payment_from: undefined,
  payment_to: undefined,
};

export const quickFilterItemsAdmin: FilterItem<TaxesFilters>[] = [
  {
    id: 'viewAll',
    label: 'View All',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
    },
  },
  {
    id: 'pendingApproval',
    label: 'Pending CSM Approval',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
      status: [PaymentStatus.Submitted],
    },
  },
  {
    id: 'pendingAttention',
    label: 'Pending Customer Attention',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
      status: [PaymentStatus.Rejected],
    },
  },
  {
    id: 'approved',
    label: 'Approved',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
      status: [PaymentStatus.Approved],
    },
  },
];

// Types of filters to be used in the QuickFilter components above the table 
export const quickFilterItemsCustomer: FilterItem<TaxesFilters>[] = [
  {
    id: 'viewAll',
    label: 'View All',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
    },
  },
  {
    id: 'thisYaxYear',
    label: 'This tax year only',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
      years: [new Date().getFullYear().toString()],
    },
  },
  {
    id: 'lastTaxYear',
    label: 'Last tax year',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
      years: [(new Date().getFullYear() - 1).toString()],
    },
  },
  {
    id: 'directPayments',
    label: 'Direct payments',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
      source: PaymentSource.Direct,
    },
  },
  {
    id: 'payrollPayments',
    label: 'Payroll payments',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
      source: PaymentSource.Payroll,
    },
  },
  {
    id: 'federalPayment',
    label: 'Payments to the IRS',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
      type: PaymentType.Federal,
    },
  },
  {
    id: 'statePayment',
    label: 'Payments to the state',
    data: {
      ...defaultTaxesFilter,
      ...emptyTaxesFilter,
      type: PaymentType.State,
    },
  },
];

export const QuickFilterSection = ({ isMobile = false }: { isMobile?: boolean }) => {
  const [query, setQuery] = useTaxesQuery();
  const { isAdmin } = useCurrentUserRole();
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterItem<TaxesFilters> | undefined>(undefined);
  
  const onQuickFilterChange = (filter: FilterItem<TaxesFilters>) => {
    if (selectedFilter && selectedFilter.id === filter.id) {
      setSelectedFilter(undefined);
      setQuery({
        ...emptyTaxesFilter,
      });
    } else {
      setSelectedFilter(filter);
      setQuery(filter.data);
    }
  };

  const areFiltersApplied: boolean = useMemo(() => {
    const filtersToCheck = omit(query, ['page', 'size']);
    return Object.values(filtersToCheck).some(value => value !== undefined);
  }, [query]);

  return (
    <Grid container sx={{ gap: 2, mb: 3, mt: 2 }} alignItems="center">
      {isMobile && (
        <Grid>
          <FormationsGhostButton
            size='large'
            rounded
            onClick={() => {setIsFilterDialogOpen(true)}}
            sx={areFiltersApplied ? {color: 'white', backgroundColor: 'blue.active'} : {}}
          >
            <FilterListIcon size={35} sx={{ pr: 1.3}}/>
          </FormationsGhostButton>
        </Grid>
      )}
      <GenericQuickFilter
        items={isAdmin ? quickFilterItemsAdmin : quickFilterItemsCustomer}
        renderItem={(filter) => (
          <Box key={filter.id}>
            <QuickFilter
              label={filter.label}
              count={0}
              selected={
                isMatch(query, omit(filter.data, ['page'])) &&
                isEqual(query.years, filter.data.years) &&
                isEqual(query.status, filter.data.status)
              }
              onClick={() => onQuickFilterChange(filter)}
              data-testid={`quick-filter-${filter.id} ${
                isMatch(query, omit(filter.data, 'page'))
                  ? 'quick-filter-selected'
                  : ''
              }`}
            />
          </Box>
        )}
      />
      {isAdmin && (
        <FormationsGhostButton
          rounded
          sx={{
            p: 0,
          }}
          onClick={() => queryClient.invalidateQueries(['direct-payments'])}
        >
          <RefreshIcon />
        </FormationsGhostButton>
      )}
      <Dialog
        open={isFilterDialogOpen}
        onClose={() => setIsFilterDialogOpen(false)}
        fullScreen
      >
        <TaxesMobileFilter
          onClose={() => setIsFilterDialogOpen(false)}
        />
      </Dialog>
    </Grid>
  );
};
