import { useMutation, useQuery } from 'react-query';
import {
  DropboxSignTemplates,
  HelloSignSignedDocument,
  getSignedDocument,
  overrideSignedDocument,
  saveHelloSignDocument,
} from 'services/dropboxSign';

export const useSignedDocument = ({
  accountId,
  template,
}: {
  accountId?: string;
  template?: DropboxSignTemplates;
}) => {
  const { data: signedDocuments, ...rest } = useQuery<
    HelloSignSignedDocument[]
  >(
    ['dropboxSignedDocuments', accountId, template],
    () => getSignedDocument(template, accountId),
    {
      enabled: !!accountId && !!template,
    },
  );
  return {
    signedDocuments: signedDocuments || [],
    ...rest,
  };
};

export const useSaveHelloSignDocument = (queryProps?: any) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (params: {
      accountId: string;
      signatureRequestId: string;
      templateName: DropboxSignTemplates;
    }) =>
      saveHelloSignDocument(
        params.accountId,
        params.signatureRequestId,
        params.templateName,
      ),
    queryProps,
  );
  return {
    saveHelloSignDocument: mutate,
    saveHelloSignDocumentAsync: mutateAsync,
    ...rest,
  };
};

export const useOverrideSignedDocument = (queryProps?: any) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (params: {
      accountId: string;
      documentId: string;
      templateName: DropboxSignTemplates;
    }) =>
      overrideSignedDocument(
        params.accountId,
        params.documentId,
        params.templateName,
      ),
    queryProps,
  );
  return {
    overrideSignedDocument: mutate,
    overrideSignedDocumentAsync: mutateAsync,
    ...rest,
  };
};
