import React, { useState } from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { styled } from '@mui/material/styles';
import {
  CheckIcon,
  CloseIcon,
  DeleteIcon as DeleteOutlinedIcon,
  EditIcon,
  InsertDriveFileIcon,
} from 'components/common/Icon';
import { BlobPreviewDialog } from 'components/FilePreview/BlobPreviewDialog';
import { TempDocument } from 'components/documentsV2/types';

const FileInfoContainer = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.others.stroke}`,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  '&:hover': {
    border: '1px solid #ffffff',
    boxShadow: theme.shadows[1],
  },
  marginBottom: theme.spacing(2),
}));

const EditNameField = ({
  editText,
  setEditText,
}: {
  editText: string;
  setEditText: Function;
}) => {
  const [error, setError] = useState<boolean>(false);

  const handleChange = (input: string) => {
    if (input) {
      setError(false);
    } else {
      setError(true);
    }
    setEditText(input);
  };

  return (
    <TextField
      error={error}
      size="small"
      id="standard-error-helper-text"
      label=""
      value={editText}
      onChange={(e) => handleChange(e.target.value)}
      helperText={error ? 'Name is required' : ''}
      variant="standard"
      sx={{ p: 0, width: 200 }}
      inputProps={{
        'data-testid': `input-file-name`,
      }}
    />
  );
};

type Props = {
  disabled?: boolean;
  disablePreview?: boolean;
  index?: number;
  file: TempDocument;
  handleDeleteFile:
    | (() => void)
    | ((file: TempDocument, index: number) => void);
  updateFileName:
    | ((editFile: TempDocument) => void)
    | ((file: TempDocument, index: number) => void);
  allowNameEdit?: boolean;
  allowFileDelete?: boolean;
  isMobile?: boolean;
  hideDelete?: boolean;
  hideEdit?: boolean;
};

export const FileUploadEditableRow = ({
  disabled = false,
  disablePreview = false,
  index = 0,
  file,
  handleDeleteFile,
  updateFileName,
  allowNameEdit = true,
  allowFileDelete = true,
  isMobile = false,
  hideDelete = false,
  hideEdit = false,
}: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editText, setEditText] = useState<string>(file.displayName);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

  const handleDeleteClick = () => {
    handleDeleteFile(file, index);
  };

  const handleConfirmEditClick = () => {
    setIsEditing(false);
    updateFileName(
      {
        ...file,
        extension: file.extension,
        displayName: editText,
        file: file.file,
      },
      index,
    );
  };
  const FileDisplayName = styled(Typography)(() => ({
    display: 'inline-block',
    width: isMobile ? 150 : 200,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }));

  const handleFileDisplayNameClick = () => {
    if (!disablePreview) {
      setIsPreviewDialogOpen(true);
    }
  };

  return (
    <FileInfoContainer
      container
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <InsertDriveFileIcon sx={{ color: 'others.stroke', mr: 1 }} />
      <Grid item xs container alignItems="center">
        {isEditing ? (
          <EditNameField editText={editText} setEditText={setEditText} />
        ) : (
          <FileDisplayName
            variant="chip"
            color="text.primary"
            sx={{ cursor: 'pointer' }}
            onClick={handleFileDisplayNameClick}
          >
            {file.displayName}
          </FileDisplayName>
        )}
      </Grid>
      {isEditing && (
        <FormationsGhostButton
          rounded
          onClick={handleConfirmEditClick}
          disabled={disabled || !editText}
          data-testid="save-name-button"
        >
          <CheckIcon />
        </FormationsGhostButton>
      )}
      {isEditing && (
        <FormationsGhostButton
          rounded
          onClick={() => setIsEditing(false)}
          disabled={disabled}
          data-testid="clear-name-button"
        >
          <CloseIcon />
        </FormationsGhostButton>
      )}
      {!isEditing && !hideEdit && (
        <FormationsGhostButton
          rounded
          onClick={() => {
            setIsEditing(true);
            setEditText(file.displayName);
          }}
          disabled={disabled || !allowNameEdit}
          data-testid="edit-button"
        >
          <EditIcon />
        </FormationsGhostButton>
      )}
      {!isEditing && !hideDelete && (
        <FormationsGhostButton
          rounded
          disabled={disabled || !allowFileDelete}
          data-testid="file-delete-btn"
          onClick={handleDeleteClick}
        >
          <DeleteOutlinedIcon data-testid="DeleteOutlinedIcon" />
        </FormationsGhostButton>
      )}
      <BlobPreviewDialog
        open={isPreviewDialogOpen}
        file={file}
        onClose={() => setIsPreviewDialogOpen(false)}
      />
    </FileInfoContainer>
  );
};
