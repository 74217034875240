import { useEffect } from 'react';
import { useDocument } from 'hooks/api/useDocuments';
import { Loading } from 'components/common';
import { FilePreviewDialog } from 'components/FilePreview';
import { showErrorToast } from 'components/toast/showToast';
import { castFDToFormationsPreviewDocument } from 'helpers/documents';
import { useMediaBreakpoints } from 'hooks';

export const LoadFilePreviewDialog = ({
  documentId,
  setSelectedDocumentId,
  accountId,
  showRequestChangeButton,
  handleRequestChange,
  showApproveButton,
  handleApprove,
}: {
  documentId: string;
  setSelectedDocumentId: Function;
  accountId: string | undefined;
  showRequestChangeButton?: boolean;
  handleRequestChange?: () => void;
  showApproveButton?: boolean;
  handleApprove?: () => void;
}) => {
  const { document, isLoading, isError } = useDocument(documentId);
  const { isMobile } = useMediaBreakpoints();

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to load document');
    }
  }, [isError]);

  if (isError) {
    setSelectedDocumentId(null);
    return null;
  }

  return (
    <>
      {isLoading || !document ? (
        <Loading />
      ) : (
        <FilePreviewDialog
          accountId={accountId ?? ''}
          open
          file={castFDToFormationsPreviewDocument(document)}
          files={[]}
          onClose={() => setSelectedDocumentId(null)}
          taxPaymentMobileView={isMobile}
          isMobile={isMobile}
          showRequestChangeButton={showRequestChangeButton}
          handleRequestChange={handleRequestChange}
          showApproveButton={showApproveButton}
          handleApprove={handleApprove}
        />
      )}
    </>
  );
};
