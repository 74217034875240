import { Box } from '@mui/material';
import {
  useNavigate,
  useCurrentUser,
  useCurrentAccount,
  useCurrentCompany,
} from 'hooks/api';
import React, { useEffect, useMemo, useState } from 'react';
import { Incorporation } from 'components/ProgressTracker/Incorporation';
import { Routes } from 'fnRoutes';
import { useMediaBreakpoints } from 'hooks';
import { AccountVerificationComponent } from 'components/ProgressTracker/AccountVerification/AccountVerificationComponent';
import { ReviewAndSignComponentBIB as ReviewAndSignComponent } from 'components/ProgressTracker/ReviewAndSign/ReviewAndSignComponentBIB';
import { Loading } from 'components/common';
import {
  isAccount1120sOnly,
  isAccountBusinessInABox,
} from 'components/ProgressTracker/util';
import { ProgressTracker1120sOnly } from 'components/ProgressTracker/ProgressTracker1120sOnly';
import { ProgressTrackerBusinessInABox } from 'components/ProgressTracker/ProgressTrackerBusinessInABox';
import { useCurrentPaymentStatus } from 'hooks/api/useStripe';
import { IncorporationStatus } from 'components/IncorporationStatus/IncorporationStatus';
import { ENTITY_MAPPING } from 'constants/common';
import { useSignedDocument } from 'hooks/api/useDropboxSign';
import { ReviewAndPayComponent } from './ReviewAndPay/ReviewAndPayComponent';
import { BusinessPackageComponent } from './BusinessPackage/BusinessPackageComponent';
import { BankSetupBIB } from './BankSetup/BankSetupBIB';

export const ProgressTracker = () => {
  const { isMobile, isDesktop } = useMediaBreakpoints();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { currentAccount, isLoading } = useCurrentAccount();
  const { currentCompany: companyData } = useCurrentCompany();
  const { alreadyPaid, isLoading: isLoadingAlreadyPaid } =
    useCurrentPaymentStatus();

  const isSoleProp = currentAccount?.entityType === ENTITY_MAPPING.sole_prop;
  const incorporationFinished = useMemo(() => {
    if (!isSoleProp) {
      return true;
    }
    return companyData?.bibHubspot?.ticketStatus === 'COMPLETED';
  }, [companyData?.bibHubspot, isSoleProp]);

  const { currentCompany } = useCurrentCompany();
  const { signatureRequestTemplate } = currentCompany || {};

  const { signedDocuments } = useSignedDocument({
    accountId: currentAccount?.id,
    template: signatureRequestTemplate,
  });

  const firstTwoSectionsCompleted = useMemo(
    () =>
      !(
        !currentUser?.dob ||
        !companyData?.stateOfIncorporation ||
        !companyData?.contactDetails ||
        !currentAccount?.homeAddress ||
        !currentUser.bankName ||
        !currentUser.routingNumber ||
        !currentUser.bankAccountNumber ||
        !currentUser.bankAccountType
      ),
    [currentUser, companyData, currentAccount],
  );

  const showIncorporationStatus = useMemo(() => {
    if (incorporationFinished || !firstTwoSectionsCompleted) {
      return false;
    }
    return true;
  }, [incorporationFinished, firstTwoSectionsCompleted]);

  const reviewAndSignCompleted = useMemo(
    () => signedDocuments && signedDocuments.length > 0,
    [signedDocuments],
  );

  const secondSectionCompleted = useMemo(() => {
    const accountVerificationItems = currentAccount?.progress.filter(
      (progressItem) => progressItem.group === 'accountVerification'
    );

    const completedSteps = accountVerificationItems?.reduce((count, item) => {
      const statusCompleted = item.status.length > 0;

      return statusCompleted ? count + 1 : count;
    }, 0);

    return completedSteps === 5;
  }, [currentAccount?.progress]);

  const bankSetupCompleted = useMemo(
    () =>
      currentAccount?.progress.some(
        (progressItem) =>
          progressItem.stage === 'bankInformation' &&
          progressItem.status.some(
            (statusItem) => statusItem.status === 'completed',
          ),
      ),
    [currentAccount?.progress],
  );

  const [expandedIncorporationSection, setExpandedIncorporationSection] =
    useState<string | false>(false);
  const [
    expandedAccountVerificationSection,
    setExpandedAccountVerificationSection,
  ] = useState<string | false>(false);
  const [expandedReviewAndPaySection, setExpandedReviewAndPaySection] =
    useState<string | false>(false);
  const [expandedReviewAndSignSection, setExpandedReviewAndSignSection] =
    useState<string | false>(false);
  const [expandedBankSetupSection, setExpandedBankSetupSection] = useState<
    string | false
  >(false);
  const [expandedBusinessPackageSection, setExpandedBusinessPackageSection] =
    useState<string | false>(false);

  const handleExpandIncorporationSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedIncorporationSection(isExpanded ? panel : false);
    };
  const handleExpandAccountVerificationSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedAccountVerificationSection(isExpanded ? panel : false);
    };
  const handleExpandReviewAndPaySection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedReviewAndPaySection(isExpanded ? panel : false);
    };
  const handleExpandedReviewAndSignSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedReviewAndSignSection(isExpanded ? panel : false);
    };
  const handleExpandBankSetupSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedBankSetupSection(isExpanded ? panel : false);
    };
  const handleExpandedBusinessPackageSection =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedBusinessPackageSection(isExpanded ? panel : false);
    };

  const onCollapseAllSections = () => {
    setExpandedIncorporationSection(false);
    setExpandedAccountVerificationSection(false);
    setExpandedReviewAndPaySection(false);
    setExpandedReviewAndSignSection(false);
    setExpandedBankSetupSection(false);
    setExpandedBusinessPackageSection(false);
  };

  const onContinueToAccountVerification = () => {
    onCollapseAllSections();
    setExpandedAccountVerificationSection('account-verification');
  };

  const onContinueToReviewAndPay = () => {
    onCollapseAllSections();
    setExpandedReviewAndPaySection('review-and-pay');
  };

  const onContinueToBankSetup = () => {
    onCollapseAllSections();
    setExpandedBankSetupSection('bank-setup');
  };

  const onContinueToBusinessPackage = () => {
    onCollapseAllSections();
    setExpandedBusinessPackageSection('business-package');
  };

  const onContinueToReviewAndSign = () => {
    onCollapseAllSections();
    setExpandedReviewAndSignSection('review-and-sign');
  }


  const handleAccountVerificationOnContinue = () => {
    if (!alreadyPaid && firstTwoSectionsCompleted) {
      onContinueToReviewAndPay();
    }
    if (alreadyPaid && firstTwoSectionsCompleted) {
      onContinueToReviewAndSign();
    }
  };

  useEffect(() => {
    if (currentUser && !currentUser.companyId) {
      navigate(`${Routes.ENTITY_SELECTION}`);
    }
    if (currentUser?.role === 'Admin') {
      // 'SuperAdmin' can still go to this page
      navigate(`${Routes.ADMIN_HOME}`);
    }
    if (currentAccount?.status?.label === 'ACTIVE') {
      navigate(`${Routes.CUSTOMER_HOME}`);
    }
  }, [currentUser, currentAccount, navigate]);

  if (isLoading || !currentAccount || isLoadingAlreadyPaid) {
    return <Loading />;
  }

  if (isAccount1120sOnly(currentAccount)) {
    return <ProgressTracker1120sOnly account={currentAccount} />;
  }
  if (isAccountBusinessInABox(currentAccount)) {
    return <ProgressTrackerBusinessInABox account={currentAccount} />;
  }

  return (
    <Box
      sx={{
        width: 'auto',
        px: isMobile ? 2 : 9,
        py: 2,
        mt: 10,
      }}
    >
      <Incorporation
        isDesktop={isDesktop}
        handleExpand={handleExpandIncorporationSection}
        expanded={expandedIncorporationSection}
        onContinueToAccountVerification={onContinueToAccountVerification}
      />
      <AccountVerificationComponent
        handleExpand={handleExpandAccountVerificationSection}
        expanded={expandedAccountVerificationSection}
        onContinueToNextSection={handleAccountVerificationOnContinue}
      />
      {!alreadyPaid && (
        <ReviewAndPayComponent
          handleExpand={handleExpandReviewAndPaySection}
          expanded={expandedReviewAndPaySection}
          disabled={!firstTwoSectionsCompleted}
        />
      )}

      {showIncorporationStatus && alreadyPaid && (
        <IncorporationStatus account={currentAccount} />
      )}

      <ReviewAndSignComponent
        handleExpand={handleExpandedReviewAndSignSection}
        expanded={expandedReviewAndSignSection}
        onContinueToNextSection={onContinueToBankSetup}
        disabled={showIncorporationStatus || !secondSectionCompleted}
      />

      <BankSetupBIB
        isDesktop={isDesktop}
        handleExpand={handleExpandBankSetupSection}
        expanded={expandedBankSetupSection}
        onContinueToNextSection={onContinueToBusinessPackage}
        disabled={!reviewAndSignCompleted}
      />

      <BusinessPackageComponent
        handleExpand={handleExpandedBusinessPackageSection}
        expanded={expandedBusinessPackageSection}
        disabled={!bankSetupCompleted}
      />
    </Box>
  );
};
