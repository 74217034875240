export const numberFormat = (
  value: number,
  defaultMinimumFraction: number = 0,
  defaultMaxFraction: number = 2,
): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: defaultMaxFraction,
    minimumFractionDigits: defaultMinimumFraction,
  }).format(value);

export const numberFormatToString = (value: number) =>
  value.toLocaleString('en-US');

export const roundOffToNearest100 = (value: number): number => {
  try {
    return Math.floor(value / 100) * 100;
  } catch (error) {
    return value;
  }
};

export const calculatePercentage = (
  partialValue: number | undefined,
  totalValue: number = 0,
): number => {
  if (!partialValue) {
    return 0;
  }
  if (partialValue > totalValue) {
    return 100;
  }
  return parseFloat(Number((100 * partialValue) / totalValue).toFixed(2));
};

export const roundToTwoDecimalPercent = (input: number): number =>
  Math.round(input * 10000) / 100;

export const roundToTwoDecimal = (input: number): number =>
  Math.round(input * 100) / 100;

export const calculatePercentageChange = (
  currentData: number,
  previousData: number,
) => {
  let percent;
  if (previousData !== 0) {
    if (currentData !== 0) {
      percent = (currentData - previousData) / previousData;
    } else {
      percent = currentData;
    }
  } else {
    percent = previousData;
  }
  return roundToTwoDecimalPercent(percent);
};

export const numberFormatHandleZero = (amount: number) => {
  const amountString = numberFormat(amount, 2);
  return amountString === '$0.00' ? '$0' : amountString;
};

export const convertNegativeZero = (num: number) =>
  Object.is(num, -0) ? 0 : num;

export const centsToDollars = (cents: number): string =>
  (cents / 100).toFixed(2);
