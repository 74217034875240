import * as yup from 'yup';
import { DOCUMENT_NOTE_MAX_LENGTH } from 'constants/common';

export const documentNoteSchema = yup.object().shape({
  documentNote: yup
    .string()
    .trim('Please enter your note')
    .required('Please enter your note')
    .max(
      DOCUMENT_NOTE_MAX_LENGTH,
      `Please limit note input to ${DOCUMENT_NOTE_MAX_LENGTH} characters.`,
    ),
});
export const documentNoteAdminSchema = yup.object().shape({
  documentNote: yup
    .string()
    .trim('Please enter your note')
    .required('Please enter your note'),
});
