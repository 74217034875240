import DialogContent from '@mui/material/DialogContent';
import {
  FormationsFormFields,
  IFormField,
  renderField,
} from 'components/common/FormationsForm2';
import { useForm } from 'react-hook-form';
import {
  Box,
  DialogTitle,
  Divider,
  Grid,
  Popover,
  Typography,
} from '@mui/material';
import { FormationsGhostButton } from 'components/common/Buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import { showErrorToast } from 'components/toast/showToast';
import i18n from 'translations/i18n';
import {
  useAccount,
  useCreateDocumentNotes,
  useCurrentUserRole,
  useDocumentNotes,
  useMarkNotesAsRead,
  useUser,
} from 'hooks/api';
import { queryClient } from 'states/reactQueryClient';
import { DOCUMENT_NOTE_MAX_LENGTH } from 'constants/common';
import { getPrettyDateTimeFromNow } from 'helpers/dateTimeFormat';
import { IDocumentNote } from 'services/documentTypes';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SendIcon } from 'components/common/Icon';
import { documentNoteAdminSchema, documentNoteSchema } from 'constants/schemas';

interface IEditNoteDialog {
  anchorEl: Element | null;
  documentId: string;
  documentAccountId: string;
  unreadNote: boolean;
  open: boolean;
  onClose: () => void;
}

const DocumentNoteItem = ({
  docNote,
  documentAccountId,
}: {
  docNote: IDocumentNote;
  documentAccountId: string;
}) => {
  const { account } = useAccount(documentAccountId);
  const { user } = useUser(account?.ownerId);
  const isLoginUserAdminNote = documentAccountId !== docNote.creatorId;
  const noteCreatorName = useMemo(() => {
    // documentAccountId will always be the customer's accountId
    // if creatorId not the same as documentAccountId, it is posted by admin
    if (documentAccountId !== docNote.creatorId) {
      return 'Formations';
    }
    // note is created by customer
    return user?.preferredName ?? user?.name?.first ?? 'Customer';
  }, [documentAccountId, docNote.creatorId, user]);
  return (
    <Grid item key={docNote.id} sx={{ p: 3 }} data-testid="document-note-item">
      <Grid
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        xs={12}
        sx={{ mb: 3 }}
      >
        <Typography variant="body2B">{noteCreatorName}</Typography>
        <Typography variant="tooltip" sx={{ color: 'text.hint' }}>
          {getPrettyDateTimeFromNow(docNote.createdAt)}
        </Typography>
      </Grid>
      {isLoginUserAdminNote && (
        <Typography
          variant="body3"
          sx={{ whiteSpace: 'pre-line', mb: 3 }}
          dangerouslySetInnerHTML={{ __html: docNote.note }}
        />
      )}
      {!isLoginUserAdminNote && (
        <Typography variant="body3" sx={{ whiteSpace: 'pre-line', mb: 3 }}>
          {docNote.note}
        </Typography>
      )}
    </Grid>
  );
};
export const EditNoteDialog = ({
  anchorEl,
  documentId,
  documentAccountId,
  unreadNote,
  open,
  onClose,
}: IEditNoteDialog) => {
  const { isLoginUserAdmin } = useCurrentUserRole();
  const noteContainerBottomRef = useRef<HTMLDivElement>(null);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const { notes } = useDocumentNotes(documentId);
  const { mutateAsync: createDocumentNote } = useCreateDocumentNotes({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['document-notes', documentId]);
    },
  });

  const { mutateAsync: markNoteAsRead } = useMarkNotesAsRead({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['document-notes', documentId]);
    },
  });

  const readNote = async () => {
    try {
      await markNoteAsRead(documentId);
      setRefreshOnClose(true);
    } catch (e: unknown) {
      if (e instanceof Error) {
        showErrorToast(e.message);
      } else {
        showErrorToast('An error occurred while updating transaction note.');
      }
    }
  };

  const executeScroll = useCallback(() => {
    noteContainerBottomRef?.current?.scrollIntoView?.({
      block: 'end',
    });
  }, [noteContainerBottomRef.current]);

  const documentNote: IFormField = {
    name: 'documentNote',
    type: isLoginUserAdmin
      ? FormationsFormFields.RichText
      : FormationsFormFields.TextArea,
    label: '',
    options: {
      minRow: isLoginUserAdmin ? 1 : 5,
      maxRow: isLoginUserAdmin ? 5 : 10,
      placeholder: i18n.t('documentHub.note.inputPlaceHolder'),
      characterLimit: DOCUMENT_NOTE_MAX_LENGTH,
      autoFocus: true,
      width: '100%',
      InputProps: {
        endAdornment: (
          <FormationsGhostButton
            rounded
            type="submit"
            style={isLoginUserAdmin ? { float: 'right' } : {}}
          >
            <SendIcon />
          </FormationsGhostButton>
        ),
      },
    },
  };

  const formInstance = useForm({
    defaultValues: {
      documentNote: '',
      mode: 'onChange',
      reValidateMode: 'onChange',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      isLoginUserAdmin ? documentNoteAdminSchema : documentNoteSchema,
    ),
  });

  useEffect(() => {
    if (notes && notes.length > 0) {
      executeScroll();
    }
  }, [notes, executeScroll]);

  useEffect(() => {
    if (unreadNote && documentId) {
      readNote();
    }
  }, [documentId, unreadNote]);

  const { handleSubmit, reset } = formInstance;

  const handleSubmitClick = async (data: { documentNote: string }) => {
    try {
      await createDocumentNote({
        note: data.documentNote,
        accountId: documentAccountId,
        documentId,
      });
      setRefreshOnClose(true);
      reset({
        documentNote: '',
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        showErrorToast(e.message);
      } else {
        showErrorToast('An error occurred while creating note.');
      }
    }
  };

  const handleOnClose = async () => {
    if (refreshOnClose) {
      await queryClient.invalidateQueries(['document-notes', documentId]);
      await queryClient.invalidateQueries([
        'documents',
        'accountId',
        documentAccountId,
      ]);
    }
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      onClose={handleOnClose}
      open={open}
    >
      <Grid
        item
        key="documentNote"
        data-testid="document-notes-container"
        xs={12}
        sx={{ my: 0, maxHeight: 300, overflow: 'hidden', overflowY: 'auto' }}
      >
        {notes?.map((n, i) => (
          <Box key={n.id} maxWidth={408}>
            <DocumentNoteItem
              docNote={n}
              documentAccountId={documentAccountId}
            />
            {i < notes.length - 1 && <Divider sx={{ my: 1 }} />}
          </Box>
        ))}
        <div ref={noteContainerBottomRef} />
      </Grid>
      {notes && <Divider />}
      <form noValidate onSubmit={handleSubmit(handleSubmitClick)}>
        <DialogTitle sx={{ px: 3, py: 2 }}>New Note</DialogTitle>
        <DialogContent sx={{ px: 3, py: 0, width: 360, paddingBottom: 2 }}>
          <Grid item key="documentNote" xs={12} sx={{ my: 0 }}>
            {renderField(documentNote, formInstance)}
          </Grid>
        </DialogContent>
      </form>
    </Popover>
  );
};
