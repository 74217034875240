import { IncorporationStatusStepper } from 'components/IncorporationStatus/IncorporationStatusStepper';
import { IAccount } from 'models/account';
import { useAccountPreference, useCompany } from 'hooks/api';
import { Alert, Box, Skeleton, Typography } from '@mui/material';
import { Company } from 'models/company';
import { AccountPreferenceSetting } from 'components/IncorporationStatus/AccountPreferenceSetting';
import { useAutoInitIncorporationStatus } from 'components/IncorporationStatus/utils';
import { ENTITY_MAPPING } from 'constants/common';

interface UIProps {
  email?: string;
  company: Company;
  accountId: string;
}

export const IncorporationStatusUI = ({
  email,
  accountId,
  company,
}: UIProps) => (
  <Box sx={{ mb: 3 }}>
    <Typography
      variant="subtitle1MB"
      color="primary.main"
      sx={{ mt: 2, mb: 2, textAlign: 'center' }}
      component="h2"
    >
      Your Incorporation Progress
    </Typography>
    <Box sx={{ p: 3 }}>
      <IncorporationStatusStepper company={company} />
      {company.bibHubspot?.ticketStatus === 'ACTION_NEEDED' && (
        <Alert severity="warning" icon={false} sx={{ mt: 2, mb: 2, p: 2 }}>
          We sent you an email with the required actions to resolve in order to
          proceed with the incorporation.{' '}
          <strong>Please check your email</strong> for the details.
        </Alert>
      )}
      <AccountPreferenceSetting
        email={email ?? ''}
        accountId={accountId}
        title="incorporation"
      />
    </Box>
  </Box>
);

interface Props {
  account: IAccount;
}

export const IncorporationStatus = ({ account }: Props) => {
  const { company, isLoading } = useCompany(account.companyId);
  const { accountPreference, isLoading: isAccountPrefLoading } =
    useAccountPreference(account.id);
  useAutoInitIncorporationStatus(company, account.progress ?? []);

  if (isLoading || isAccountPrefLoading) {
    return <Skeleton height={200} />;
  }

  if (
    company?.bibHubspot === undefined ||
    accountPreference === undefined ||
    company?.entityType !== ENTITY_MAPPING.sole_prop
  ) {
    return null;
  }

  return (
    <IncorporationStatusUI
      email={account.ownerEmail}
      accountId={account.id}
      company={company}
    />
  );
};
